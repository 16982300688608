import {useSelector} from 'react-redux';
import {ReduxState} from '../store/types';
import {PermissionType} from '../store/types/Permission';
import {createContext, useContext, useMemo} from 'react';
import {getPermission} from '../utils/permissions/getPermission';
import {sections} from '../components/Sidebar/Sidebar.utils';
import {IndividualType} from "../store/types/CustomerInfo";
import {Routes, skippedRoutesForManager, skippedRoutesForSupervisor} from '../routes/routes';

export const usePermissions = (...components: string[]) => {
    const permissions = useSelector(
        (state: ReduxState) => state.permissions?.permissions,
    );

    return getPermission(permissions, ...components);
};

export const useRawPermissions = () => {
    return useSelector(
        (state: ReduxState) => state.permissions?.permissions,
    );
};

export const PermissionContext = createContext(PermissionType.Visible);
export const usePermissionContext = () => useContext(PermissionContext);

export const useDefaultScreenBasedOnPermissions = () => {
    const permissions = useSelector(
        (state: ReduxState) => state.permissions?.permissions,
    );

    const userType = useSelector(
        (state: ReduxState) => state.generic?.sessionData?.individual_type || IndividualType.Manager,
    );

    return useMemo(() => {
        for (const section of Object.keys(sections)) {
            for (const screen of sections[section].sections) {

                if ((userType == IndividualType.Supervisor || userType == IndividualType.SupervisorName) && skippedRoutesForSupervisor.includes(screen.route)) {
                    continue
                }

                if (userType == IndividualType.Manager && skippedRoutesForManager.includes(screen.route)) {
                    continue
                }

                if (
                    getPermission(permissions, ...screen.permission) !==
                    PermissionType.Hidden
                ) {
                    return screen.route;
                }
            }
        }
        return userType == IndividualType.Supervisor ? Routes.SupervisorPanel : Routes.Dashboard;

    }, [permissions, userType]);
};
