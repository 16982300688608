import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {IconButton} from '@material-ui/core';
import {Formik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {actions} from '../../store';
import {ReduxState} from '../../store/types';
import {EditPortalUserDetailsProps, useStyles} from './editPortalUserUtils';
import {ChevronLeft} from '@material-ui/icons';
import Loader from '../../components/Loader/Loader';
import {Colors} from '../../styles/Colors';
import {
    CreateNewPortalUserFormData,
    emailValidError,
    nameMaxLengthError,
    requiredFieldError,
    SecurityValidationSchema, SupervisorValidationSchema,
} from '../../components/PortalUsers/utils';
import PortalUserSettings from '../../components/PortalUsers/Settings/PortalUserSettings';
import PortalUserSecurity from '../../components/PortalUsers/Security/PortalUserSecurity';
import history from '../../history';
import {Routes} from '../../routes/routes';
import dayjs from '../../services/customDayJs';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import {getTabNumber} from '../../utils/getTabNumber';
import * as Yup from 'yup';
import {ConfigData} from '../../store/types/ConfigData';
import usePageTitle from '../../hooks/usePageTitle';
import _ from 'lodash';
import DetailsWrapper from '../../components/DetailsWraper/DetailsWrapper';
import {useRawPermissions} from "../../hooks/usePermissions";
import {generateTabs} from "../../utils/generateTabs";
import {Permission, PermissionType} from "../../store/types/Permission";
import PermissionProvider from "../../components/PermissionProvider/PermissionProvider";
import {convertUtcToUserLocalTime} from "../../utils/dateWithTimezoneConversion";
import NotFound from '../NotFound/NotFound';
import ErrorNotification from "../../components/TabErrors/ErrorNotification";
import {portalUserStatusBasedOnDates} from './utils';
import {useIndividualTypeHook} from "../../hooks/agents/useIndividualTypeHook";

const USER_NOT_FOUND_API_ERROR_CODE = 'Server.Customer.i_individual.not_found';

const EditPortalUser: React.FC<EditPortalUserDetailsProps> = ({id}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();
    const {isSupervisor, i_account, i_individual} = useIndividualTypeHook()

    const permissions = useRawPermissions();

    const [toastApiErrorVisible, setToastApiErrorVisible] = useState(false);

    const {isLoading, editedPortlUser, isFormSaving, apiErrors} = useSelector(
        (state: ReduxState) => state.company,
    );

    const [isSvTypeSelected, setIsSvTypeSelected] = useState(editedPortlUser?.customer_individual_info.type == 'hunt_group_supervisor' || false)

    const {roleList} = useSelector((state: ReduxState) => state.company);

    const {timeZonesList, languagesList} = useSelector(
        (state: ReduxState) => state.generic,
    );

    const config = useSelector<ReduxState, ConfigData | undefined>(
        (state) => state.generic?.configData,
    );

    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const userDateFormat = useSelector<ReduxState, string>(
        (state) =>
            state.generic.globalCustomerInfo?.customer_info?.out_date_format ||
            '',
    );

    const saveClicked = useSelector(
        (state: ReduxState) => state.tabs?.saveForms || false,
    );

    const [tabIndex, setTabIndex] = useState(getTabNumber([0, 1, 2]));
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

    const CreateNewPortalUserValidationSchema = Yup.object().shape({
        role: Yup.string().required(requiredFieldError).nullable(),
        password: Yup.string()
            .test(
                'passLength',
                t('enums:passwordRules.PasswordMinLength', {
                    charLength: config?.Web.PasswordMinLength,
                }),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordMinLength
                    ) {
                        return (
                            value.length >=
                            config?.Web.PasswordMinLength
                        );
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                t('enums:passwordRules.cr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /[a-zA-Z]/g.test(value);
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                t('enums:passwordRules.nr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /\d/.test(value);
                    }

                    return true;
                },
            )
            .max(32, nameMaxLengthError)
            .required(requiredFieldError),
        login: Yup.string()
            .matches(
                /(^[A-Za-z0-9-_@.]*$)/,
                t('errors:portalUsers.portalRegex'),
            )
            .required(requiredFieldError),
        email: Yup.string().email(emailValidError).notRequired(),
        activationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: t('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .nullable(),
        expirationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: t('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .test(
                'dobY',
                t('errors:portalUsers.expirationDateAfterActivation'),
                (value, ctx) => {
                    const expired = value
                        ? dayjs(value, 'YYYY-MM-DD').toDate()
                        : null;
                    const activation = Yup.ref('activationDate')
                        ? dayjs(
                            ctx.parent.activationDate,
                            'YYYY-MM-DD',
                        ).toDate()
                        : null;

                    if (!expired || !activation || !ctx.parent.activationDate) {
                        return true;
                    }

                    return activation < expired;
                },
            )
            .nullable()
            .notRequired(),
    });

    const CreateNewSvPortalUserValidationSchema = Yup.object().shape({
        password: Yup.string()
            .test(
                'passLength',
                t('enums:passwordRules.PasswordMinLength', {
                    charLength: config?.Web.PasswordMinLength,
                }),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordMinLength
                    ) {
                        return (
                            value.length >=
                            config?.Web.PasswordMinLength
                        );
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                t('enums:passwordRules.cr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /[a-zA-Z]/g.test(value);
                    }

                    return true;
                },
            )
            .test(
                'passContainLetters',
                t('enums:passwordRules.nr'),
                function (value?: string) {
                    if (
                        value !== undefined &&
                        config?.Web.PasswordComplexityRules
                    ) {
                        return /\d/.test(value);
                    }

                    return true;
                },
            )
            .max(32, nameMaxLengthError)
            .required(requiredFieldError),
        login: Yup.string()
            .matches(
                /(^[A-Za-z0-9-_@.]*$)/,
                t('errors:portalUsers.portalRegex'),
            )
            .required(requiredFieldError),
        email: Yup.string().email(emailValidError).notRequired(),
        activationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: t('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .nullable(),
        expirationDate: Yup.string()
            .test('past', (value, {createError, path}) => {
                if (!value) {
                    return true;
                }

                const date = dayjs(value, 'YYYY-MM-DD');
                const today = dayjs().startOf('date');

                const convertedValue = convertUtcToUserLocalTime(
                    value,
                    timezoneOffset,
                    userDateFormat,
                );

                return date >= today
                    ? true
                    : createError({
                        path,
                        message: t('errors:portalUsers.activationDate', {
                            value: convertedValue,
                        }),
                    });
            })
            .test(
                'dobY',
                t('errors:portalUsers.expirationDateAfterActivation'),
                (value, ctx) => {
                    const expired = value
                        ? dayjs(value, 'YYYY-MM-DD').toDate()
                        : null;
                    const activation = Yup.ref('activationDate')
                        ? dayjs(
                            ctx.parent.activationDate,
                            'YYYY-MM-DD',
                        ).toDate()
                        : null;

                    if (!expired || !activation || !ctx.parent.activationDate) {
                        return true;
                    }

                    return activation < expired;
                },
            )
            .nullable()
            .notRequired(),
    });

    const formValidationSchema = useMemo(() => {

        if(!isSupervisor && isSvTypeSelected){
            return  CreateNewPortalUserValidationSchema.concat(SecurityValidationSchema.concat(SupervisorValidationSchema))
        }else if (isSupervisor)
        {
            return CreateNewSvPortalUserValidationSchema
        }else{
            return CreateNewPortalUserValidationSchema
        }

    }, [isSvTypeSelected, isSupervisor])

    const initialValues = useMemo(
        () => ({
            login: editedPortlUser?.customer_individual_info.login || '',
            password: editedPortlUser?.customer_individual_info.password || '',
            role:
                roleList?.find(
                    (e) =>
                        e.i_role ===
                        editedPortlUser?.customer_individual_info.i_role,
                )?.name || '',
            email: editedPortlUser?.customer_individual_info.email || '',
            activationDate:
                editedPortlUser?.customer_individual_info.activation_date ||
                null,
            expirationDate:
                editedPortlUser?.customer_individual_info.expiration_date ||
                null,
            timezone:
                timeZonesList.find(
                    (e) =>
                        e.i_time_zone ===
                        editedPortlUser?.customer_individual_info.i_time_zone,
                )?.time_zone_name || '',
            language:
                languagesList?.find(
                    (e) =>
                        e.iso_639_1 ===
                        editedPortlUser?.customer_individual_info.lang,
                )?.name || '',
            ipAddresses: editedPortlUser?.customer_individual_info
                .login_allowed_ip_list
                ? editedPortlUser.customer_individual_info.login_allowed_ip_list.map(
                    (e, index) => {
                        return {
                            isValueRequired: !!e.ip,
                            id: index,
                            value: e.ip,
                        };
                    },
                )
                : [
                    {
                        id: 0,
                        value: '',
                    },
                ],
            extension: editedPortlUser?.customer_individual_info?.type == 'hunt_group_supervisor' ? editedPortlUser.customer_individual_info.extensionDetails : null
        }),
        [editedPortlUser, timeZonesList],
    );


    const {login} = useSelector((state: ReduxState) => state.auth);
    const isTheUserEqualsCurrent = useMemo(() => {
        return (login || '').toUpperCase() === (initialValues?.login || '').toUpperCase();
    }, [login, initialValues?.login]);

    const iTimeZone = useSelector(
        (state: ReduxState) => state.myProfile?.customer?.iTimeZone,
    );

    const currentUserTimeZoneName = useMemo(() => {
        return timeZonesList?.find(e => e.i_time_zone === iTimeZone)?.time_zone_name
            ?? 'Europe/Kyiv';
    }, [iTimeZone, timeZonesList]);

    const onSubmitForm = useCallback(
        (form: CreateNewPortalUserFormData, blockRedirection: boolean) => {
            // @ts-ignore
            dispatch(
                actions.editPortalUser.request({
                    // @ts-ignore
                    initialValues: {
                        ...initialValues,
                        activationDate: initialValues.activationDate
                            ? dayjs(initialValues.activationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        expirationDate: initialValues.expirationDate
                            ? dayjs(initialValues.expirationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                    },
                    changedValues: {
                        ...form,
                        activationDate: form.activationDate
                            ? dayjs(form.activationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        expirationDate: form.expirationDate
                            ? dayjs(form.expirationDate || '').format(
                                'YYYY-MM-DD',
                            )
                            : null,
                        // @ts-ignore
                        extension: roleList?.find(role => role.name == form.role)?.i_role_type == 7 ?
                            form.extension?.i_account : undefined
                    },
                    redirectTab: tabIndex,
                    i_individual: id,
                    blockRedirection: blockRedirection
                }),
            );
        },
        [initialValues, id, tabIndex],
    );


    useEffect(() => {

        if (id && (!editedPortlUser || editedPortlUser?.customer_individual_info?.i_individual?.toString() !== id?.toString())) {
            dispatch(actions.getPortalUserInfo.request({i_individual: id}));
        }

    }, []);

    useEffect(() => {

        if (apiErrors != undefined && tabIndex != 0 && !toastApiErrorVisible) {
            setToastApiErrorVisible(true);
        }

    }, [apiErrors]);


    const backToList = () => {
        history.push(Routes.PortalUsers);
    };

    const onDelete = () => {
        setIsRemoveModalOpen(true);
    };

    const removePortalUser = () => {
        dispatch(
            actions.deletePortalUser.request({
                i_individual: id,
                callback: backToList,
            }),
        );
    };

    if (isLoading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="edit-ring-group-sending-loader"/>
            </div>
        );
    }

    if (apiErrors?.faultcode === USER_NOT_FOUND_API_ERROR_CODE) {
        return (
            <NotFound/>
        );
    }

    const top = (
        <div className={classes.headerWrapper}>
            <div className={classes.extensionHeader}>
                <IconButton style={{marginRight: 10}} onClick={backToList}>
                    <ChevronLeft htmlColor={Colors.Text}/>
                </IconButton>
                <label>{t('screens:portalUsers.portalUser')}</label>
                <p className={classes.subTitle}>{initialValues.login}</p>
            </div>
            <div className={classes.statusWrapper}>
                <p>{t('common:status')}</p>
                <p className={classes.status}>
                    {
                        portalUserStatusBasedOnDates(
                            initialValues.activationDate ? initialValues.activationDate + '' : null,
                            initialValues.expirationDate ? initialValues.expirationDate + '' : null,
                            initialValues.timezone, currentUserTimeZoneName)
                    }
                </p>
            </div>
        </div>
    );



    const {tabNames, tabs, perm} = generateTabs(
        [
            {
                title: t('common:settings'),
                permission:
                Permission.MyCompany.PortalUsers.PortalUsersDetails.PortalUsersSettings.value,
                tab: <PortalUserSettings
                    tabIndex={0}
                    tabName={t('common:settings')}
                    callback={
                        () => dispatch(actions.saveForms({visible: true}))
                    }
                    callback2={(flag) => setIsSvTypeSelected(flag)}
                    isSupervisor={isSupervisor}
                />,
                customizePermission: isSupervisor ?
                    () => i_account == editedPortlUser?.customer_individual_info?.i_account ?
                        PermissionType.Visible : PermissionType.ReadOnly : undefined
            },
            {
                title: t('screens:portalUsers.security'),
                permission:
                Permission.MyCompany.PortalUsers.PortalUsersDetails.Security.value,
                tab: (
                    <PortalUserSecurity key={'tab-2'} tabName={t('screens:portalUsers.security')} tabIndex={1}/>
                ),
                customizePermission: isSupervisor ? () => i_account == editedPortlUser?.customer_individual_info?.i_account ? PermissionType.Visible : PermissionType.ReadOnly : undefined

            },
        ],
        permissions,
    );


    return (
        <Formik
            initialValues={initialValues}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            validate={(values: any) => {
                try {
                    if ((!values.activationDate && !initialValues.activationDate) || (
                        values.activationDate && initialValues.activationDate &&
                        values.activationDate === initialValues.activationDate
                    )) {
                        values = {
                            ...values,
                            activationDate: null,
                        };
                    }
                    if ((!values.expirationDate && !initialValues.expirationDate) || (
                        values.expirationDate && initialValues.expirationDate &&
                        values.expirationDate === initialValues.expirationDate
                    )) {
                        values = {
                            ...values,
                            expirationDate: null,
                        };
                    }

                    formValidationSchema.validateSync(values, {
                        abortEarly: false,
                        context: values,
                    });
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                } catch (error: any) {
                    if (error.name !== 'ValidationError') {
                        throw error;
                    }

                    return error.inner.reduce(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (errors: any, currentError: any) => {
                            errors = _.set(
                                errors,
                                currentError.path,
                                currentError.message,
                            );
                            return errors;
                        },
                        {},
                    );
                }

                return {};
            }}
            // @ts-ignore
            onSubmit={(form) => onSubmitForm(form, false)}
            enableReinitialize
            validateOnChange={false}
        >
            {({submitForm, dirty, values}) => (
                <>
                    <DetailsWrapper
                        tabs={tabs}
                        top={
                            <PermissionProvider
                                permission={
                                    Permission.MyCompany.PortalUsers.PortalUsersDetails.value
                                }
                            >
                                {top}
                            </PermissionProvider>
                        }
                        tabsNames={tabNames}
                        defaultTabIndex={tabIndex}
                        onTabChange={(_, index) => {
                            dispatch(actions.saveForms({visible: false}))
                            setTabIndex(index);
                        }}
                        showActionButtons
                        saveEnable={dirty}
                        preventIsOpen={dirty && !isRemoveModalOpen}
                        onBackPress={backToList}
                        onSavePress={() => {
                            submitForm().then(() => {
                                dispatch(actions.saveForms({visible: true}));
                                setToastApiErrorVisible(false);
                            })
                        }}
                        onDeletePress={onDelete}
                        // @ts-ignore
                        preventSavePress={() => onSubmitForm(values, true)}
                        deleteText={t(
                            'screens:portalUsers.deletePortalUserButton',
                        )}
                        deleteEnable={!isTheUserEqualsCurrent}
                        deletePermission={
                            Permission.MyCompany.PortalUsers.PortalUsersDetails.DeletePortalUser.value
                        }
                        perm={perm}
                    >
                        {isFormSaving && (
                            <Loader
                                dataQa="edit-ring-group-sending-loader"
                                absolutePosition
                            />
                        )}
                        <AlertDialog
                            isOpen={isRemoveModalOpen}
                            dataQa="remove-ringgroup-modal"
                            contentClass="medium-width-modal-delete"
                            hideHeader={true}
                            className={classes.dialog}
                            description={t(
                                'screens:portalUsers.deletePortalUser',
                                {name: initialValues.login},
                            )}
                            dialogActionsButtons={[
                                <DialogButton
                                    key="cancel"
                                    label={t('common:cancel')}
                                    onClick={() => setIsRemoveModalOpen(false)}
                                />,
                                <DialogButton
                                    key="delete"
                                    label={t('common:delete')}
                                    className={classes.primaryModalButton}
                                    onClick={removePortalUser}
                                />,
                            ]}
                        />
                        <ErrorNotification tabName={tabNames[tabIndex]}
                                           isSubmitting={saveClicked}/>
                        <ErrorNotification tabName={tabNames[tabIndex]}
                                           isSubmitting={toastApiErrorVisible}/>
                    </DetailsWrapper>
                </>
            )}
        </Formik>
    );
};

export default EditPortalUser;
