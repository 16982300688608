export enum Routes {
    Default = '/',
    Extensions = '/extensions',
    ExtensionsDetails = '/extensions/:id',
    SipTrunkDetails = '/sip-trunks/:id',
    FaxMailboxes = '/fax-mailboxes',
    FaxMailboxesDetails = '/fax-mailboxes/:id',
    MyCompany = '/my-company',
    MyProfile = '/my-profile',
    PaymentInvoices = '/payment-invoices',
    Dashboard = '/dashboard',
    SupervisorPanel = '/supervisor-panel',
    Wallboard = '/wallboard',
    RingGroups = '/ring-groups',
    RingGroupsDetails = '/ring-groups/:id',
    AutoAttendants = '/auto-attendants',
    AutoAttendantsDetails = '/auto-attendants/:id',
    AutoAttendantsMenu = '/auto-attendants/:autoAttendantId/menu/:menuId',
    PhoneBook = '/phone-book',
    CallActivity = '/call-activity',
    CallRecordings = '/call-recordings',
    DidNumbers = '/did-numbers',
    Devices = '/devices',
    DevicesDetails = '/devices/:id',
    SignIn = '/sign-in',
    PasswordRecovery = '/password-recovery',
    ResetPassword = '/reset-password',
    PasswordExpiration = '/password-expired',
    ChangePassword = '/change-password',
    AddRingGroup = '/add-ring-group',
    CallSettings = '/call-settings',
    PortalUsers = '/portal-users',
    EditPortalUser = '/portal-users/:id',
    Billing = '/billing',
    SipTrunks = '/sip-trunks',
    CallParkingSlots = '/parking-slots'
}

export const skippedRoutesForSupervisor:String[] = [Routes.Dashboard, Routes.CallActivity, Routes.MyCompany];
export const skippedRoutesForManager:String[] = [Routes.SupervisorPanel];

export enum PublicRoutes {
    SignIn = Routes.SignIn,
    PasswordRecovery = Routes.PasswordRecovery,
    ResetPassword = Routes.ResetPassword,
    PasswordExpiration = Routes.PasswordExpiration,
}

export const getRoute = (route: string, options: object) => {
    let output = route;

    for (const [key, value] of Object.entries(options)) {
        const search = `:${key}`;

        // @ts-ignore
        output = output.replace(search, value);
    }

    return output;
};
