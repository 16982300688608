import { makeStyles } from '@material-ui/core';
import { Colors } from '../../styles/Colors';
import { LocaleLanguages, TimeZone } from '../../store/types/TimeZone';
import { ChangePasswordFormType } from '../../components/Forms/MyProfile/ChangePassword/ChangePassword';
import i18n from 'i18next';

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        position: 'relative',
        '& .MuiButton-label': {
            color: Colors.White,
            paddingRight: 25,
            paddingLeft: 25,
        },
        display: 'flex',
        flexDirection: 'column',
    },

    headerDetails: {
        display: 'flex',
        flexDirection: 'column',
        fontSize:16,
        '& h4':{
            margin:0,
            color:Colors.Gray9,
            textTransform: 'capitalize',
            lineHeight:'28px',
            fontSize:16
        },
        '& span':{
            margin:0,
            color:Colors.Text,
            fontWeight:500,
            lineHeight:'28px',
            fontSize:16
        }
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        backgroundColor: Colors.SmokeBackground,
    },
    headerWrapper: {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        padding: '56px 90px 45px 90px',
        backgroundColor: Colors.White,
        alignItems: 'center',
        '& h3': {
            fontSize: 22,
            margin: 0,
        },
        '& p': {
            fontSize: 16,
            color: Colors.Gray5,
            margin: 0,
        },
    },
    statusWrapper: {
        display: 'flex',
        flexDirection: 'column',
        '& > :last-child': {
            color: Colors.Text,
        },
    },
    extensionHeader: {
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        alignItems: 'center',
        maxWidth: 'calc(100vw - 420px)',
        lineHeight: '39px',
        minWidth: 200,
        '& label': {
            marginRight: 16,
            fontWeight: 300,
            fontSize: 32,
        },
        '& p': {
            fontWeight: 500,
            fontSize: 32,
            color: Colors.Text,
        },
    },
    subTitle: {
        color: Colors.Text,
        fontSize: 32,
        fontWeight: 500,
    },
    extensionTop: {
        paddingTop: 57,
        display: 'flex',
        justifyContent: 'space-between',
    },
    loader: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomBar: {
        '& button': {
            width: 96,
        },
    },

    addButton: {
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    primaryModalButton: {
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },
    status: {
        fontWeight: 500,
    },
    dialog: {
        '& .MuiDialogContent-root': {
            background: Colors.White,
        },
    },
}));
export type EditMyProfileForm = EditMyProfileSettingsForm &
    ChangePasswordFormType;

export type EditMyProfileSettingsForm = {
    login?: string;
    email?: string;
    language?: LocaleLanguages;
    timeZone?: TimeZone;
};

export const requiredField = i18n.t('errors:common.emptyInput');
export const passwordDontMatch = i18n.t('errors:loginForm.passwordDontMatch');
export const newPasswordIsTheSameAsOld = i18n.t(
    'errors:company.newPasswordIsTheSameAsOld',
);
