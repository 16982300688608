import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import history from '../../../history';
import {APIErrorInterface, ReduxState} from '../../../store/types';
import {useDispatch, useSelector} from 'react-redux';
import Loader from '../../../components/Loader/Loader';
import {actions} from '../../../store';
import {IncomingFormType} from '../../../components/Forms/AutoAttendants/Incoming/utils';
import {getTabNumber} from '../../../utils/getTabNumber';
import {DialogButton} from '../../../components/AlertDialog/DialogContainer';
import AlertDialog from '../../../components/AlertDialog/AlertDialog';
import {Routes} from '../../../routes/routes';
import {AutoAttendantDetailsPayload, useStyles} from './utils';
import {IvrFlowFormType} from '../../../components/Forms/AutoAttendants/IvrFlowDiagram/IvrFlowDiagram';
import {ExtensionCallBarringFormType} from '../../../components/Forms/Extensions/CallBarring/CallBarringForm.utils';
import {ExtensionCallRecordingFormType} from '../../../components/Forms/Extensions/CallRecording.utils';
import {ExtensionPlanFormType} from '../../../components/Forms/Extensions/PlanForm.utils';
import DetailsHeader from './DetailsHeader';
import CallFlowTab from './CallFlowTab';
import {useAutoAttendantDetailsForm} from '../../../hooks/useAutoAttendantDetailsForm';
import MenuTab from './MenuTab';

import AutoAttendantExtensionTab from './AutoAtendantExtensionTab';
import {MainProductType} from '../../../components/Extensions/MainProductSelectFiled/utils';
import PlanTab from './PlanTab';
import usePageTitle from '../../../hooks/usePageTitle';
import {TabInterface} from '../../../components/Tabs/Tabs.utils';
import CallScreeningTab from '../../../components/Tabs/CallScreeningTab';
import CallForwardingTab from '../../../components/Tabs/CallForwardingTab';
import {CallScreeningFormType} from '../../../components/Forms/Extensions/CallScreening/CallScreeningForm.utils';
import CallHistoryForm from '../../../components/Forms/Extensions/CallHistory/CallHistoryForm';
import {ExtensionFormType} from '../../../components/Forms/AutoAttendants/ExtensionForm.utils';
import CallSettingsTab from './CallSettingsTab';
import {
    ExtensionCallForwardingFormType
} from '../../../components/Forms/Extensions/CallForwarding/CallForwardingForm.utils';
import DetailsWrapper from '../../../components/DetailsWraper/DetailsWrapper';
import {useRawPermissions} from '../../../hooks/usePermissions';
import {generateTabs} from '../../../utils/generateTabs';
import {Permission, PermissionType} from '../../../store/types/Permission';
import PermissionProvider from '../../../components/PermissionProvider/PermissionProvider';
import {ExtensionsListItem} from '../../../store/reducers/extensions/extensions/reducer';
import {getPermission} from '../../../utils/permissions/getPermission';
import {AUTO_ATTENDANT_NOT_FOUND_API_ERROR} from '../../../store/sagas/autoAttendants/saga';
import NotFound from '../../NotFound/NotFound';
import ErrorNotification from "../../../components/TabErrors/ErrorNotification";
import {indexOf} from "lodash";
import { PreferedIvrLanguageFormType } from '../../../components/Forms/CallSettings/CallBarring/utils';
import {useIndividualTypeHook} from "../../../hooks/agents/useIndividualTypeHook";

export type EditAutoAttendantForm = ExtensionFormType &
    IncomingFormType &
    ExtensionPlanFormType &
    ExtensionCallBarringFormType &
    ExtensionCallRecordingFormType &
    IvrFlowFormType &
    CallScreeningFormType &
    ExtensionCallForwardingFormType &
    { onHoldMusicStatus: boolean, onHoldMusicName?: string } &
    PreferedIvrLanguageFormType;

type AutoAttendantDetailsProps = {
    id: string;
    actionButtonVisible?: boolean;
};

const AutoAttendantDetails: React.VFC<AutoAttendantDetailsProps> = (
    {
        id,
        actionButtonVisible,
    }) => {
    const [tabIndex, setTabIndex] = useState(
        getTabNumber([...Array(8).keys()]),
    );

    const permissions = useRawPermissions();

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();

    const [isDirty, setIsDirty] = useState(false);

    const [saveEnabled, setSaveEnabled] = useState(false);
    const [isFormSaving, setIsFormSaving] = useState(false);

    const loading = useSelector<ReduxState, boolean>(
        (state) => !!state.autoAttendants.isFormDataLoading,
    );

    const autoAttendantInfo = useSelector<ReduxState, ExtensionsListItem | undefined>(
        (state: ReduxState) => state.autoAttendants.autoAttendantInfo,
    );

    const apiError = useSelector<ReduxState, APIErrorInterface | undefined>(
        (state: ReduxState) => state.autoAttendants?.apiError
            || state.extensions?.apiError
            || state.extensions?.sipInfoErrors
            || state.extensions?.fetchApiError
            || state.extensions?.fetchDialingRulesApiError
        ,
    );

    const saveClicked = useSelector(
        (state: ReduxState) => state.tabs?.saveForms || false,
    );

    const {deletingInProgress} = useSelector(
        (state: ReduxState) => state.autoAttendants,
    );

    const formData = useRef<AutoAttendantDetailsPayload>();

    const formsSubmitFuncRef = useRef<
        { [key in string]: (() => void) | undefined }
    >({
        ivrDiagram: undefined,
        extension: undefined,
        callSettings: undefined,
        callScreening: undefined,
        plan: undefined,
        callForwarding: undefined,
    });

    const formsErrorsValidationFuncRef = useRef<
        { [key in string]: (() => boolean) | undefined }
    >({
        ivrDiagram: undefined,
        extension: undefined,
        callSettings: undefined,
        callScreening: undefined,
        plan: undefined,
        callForwarding: undefined,
    });

    const formsIsDirtyFuncRef = useRef<{ [key in string]: boolean }>({
        ivrDiagram: false,
        extension: false,
        callSettings: false,
        callScreening: false,
        plan: false,
        callForwarding: false,
    });

    const {
        i_account,
        extension_id,
        i_c_ext,
        i_customer,
        delete_enable,
        um_domain,
        account_id,
        allTabsLoaded,
    } = useAutoAttendantDetailsForm(id);

    const {isSupervisor} = useIndividualTypeHook()

    const [removeModal, setRemoveModal] = useState<{
        isOpen: boolean;
        name?: string;
        id?: string;
        i_customer?: number;
    }>();

    useEffect(() => {
        (allTabsLoaded && isDirty) || actionButtonVisible
            ? setSaveEnabled(true)
            : setSaveEnabled(false);
    }, [allTabsLoaded, isDirty]);

    const checkAllDirtyState = () => {
        let isAnyFormDirty = false;

        Object.keys(formsIsDirtyFuncRef.current).forEach((key) => {
            if (formsIsDirtyFuncRef.current[key]) {
                isAnyFormDirty = true;
            }
        });

        isAnyFormDirty ? setIsDirty(true) : setIsDirty(false);
    };

    useEffect(() => {
        if (account_id && um_domain) {
            dispatch(
                actions.getCallFlowTabData.request({
                    id: account_id,
                    um_domain,
                }),
            );
        }
    }, [account_id, um_domain]);

    useEffect(() => {
        dispatch(
            actions.getAutoAttendantBasicDetailsData.request({
                id,
            }),
        );
    }, []);

    useEffect(() => {
        if (i_account && autoAttendantInfo) {
            dispatch(actions.getAutoAttendantExtensionTabData.request());
            dispatch(
                actions.getAutoAttendantCallSettingsTabData.request({
                    i_account,
                }),
            );
        }
    }, [i_account, autoAttendantInfo]);

    useEffect(() => {
        if (i_account) {
            dispatch(
                actions.getAutoAttendantPlanTabData.request({i_account}),
            );
            dispatch(actions.getCallForwardingTabData.request({i_account}));
        }

        if (i_account && i_customer) {
            dispatch(
                actions.getCallScreeningTabData.request({
                    i_account,
                    i_customer,
                }),
            );
        }
    }, [i_account, i_customer]);

    const onBackPress = useCallback(() => {
        history.push(Routes.AutoAttendants);
    }, []);

    const deleteExtension = () => {
        if (removeModal?.id && removeModal?.i_customer) {
            dispatch(
                actions.deleteCustomerAutoAttendant.request({
                    id: removeModal.id,
                    i_customer: removeModal.i_customer,
                    callback: () => {
                        history.push(Routes.AutoAttendants);
                    },
                }),
            );
            setRemoveModal({...removeModal, isOpen: false});
            setIsDirty(false);
        }
    };

    const showRemoveExtensionModal = (id: string, i_customer: number) => {
        setRemoveModal({
            isOpen: true,
            id,
            name: id,
            i_customer: i_customer,
        });
    };

    const closeRemoveDialog = () =>
        setRemoveModal({
            ...removeModal,
            isOpen: false,
        });

    const handleDataFormUpdate = (form: Partial<EditAutoAttendantForm>) => {
        formData.current = {
            init: {...formData.current?.init},
            updated: {...formData.current?.updated, ...form},
        };
    };

    const handleDataInitFormUpdate = (form: Partial<EditAutoAttendantForm>) => {
        formData.current = {
            init: {...formData.current?.init, ...form},
            updated: {...formData.current?.updated},
        };
    };

    const setSubmitFunc = (
        funcName: keyof typeof formsSubmitFuncRef.current,
        func: () => void,
    ) => {
        formsSubmitFuncRef.current[funcName] = func;
    };

    const setIsDirtyFunc = (
        funcName: keyof typeof formsSubmitFuncRef.current,
        isDirty: boolean,
    ) => {
        formsIsDirtyFuncRef.current[funcName] = isDirty;
        checkAllDirtyState();
    };

    const setFormIsValidFunc = (
        funcName: keyof typeof formsErrorsValidationFuncRef.current,
        func: () => boolean,
    ) => {
        formsErrorsValidationFuncRef.current[funcName] = func;
    };

    const tabProps = {
        handleSubmitForm: handleDataFormUpdate,
        handleInitDataChange: handleDataInitFormUpdate,
        handleDirtyChange: setIsDirtyFunc,
        handleSetSubmitFunc: setSubmitFunc,
        handleSetIsValidFunc: setFormIsValidFunc,
    } as TabInterface;

    const submitAllForms = () => {
        Object.keys(formsSubmitFuncRef.current).forEach((key) => {
            formsSubmitFuncRef.current?.[key]?.();
        });
    };

    const areAllFormsValid = () => {
        let formsAreValid = true;
        Object.keys(formsErrorsValidationFuncRef.current).forEach((key) => {
            let setTabError = false;
            if (
                formsErrorsValidationFuncRef.current[key] !== undefined &&
                !formsErrorsValidationFuncRef.current[key]?.()
            ) {
                formsAreValid = false;
                setTabError = true;
            } else {
                setTabError = false
            }
            // @ts-ignore
            markTabAsHaveError(tabsNameResolver[key], tabNames.indexOf(tabsNameResolver[key]), setTabError);

        });
        return formsAreValid;
    };

    const onSubmitExtensionDetails = (blockRedirection?: boolean) => {
        setIsFormSaving(true)
        submitAllForms();
        let formsAreValid = true;
        setTimeout(() => {
            formsAreValid = areAllFormsValid();
        }, 0);

        setTimeout(() => {
            if (formsAreValid) {
                setTimeout(() => {
                    if (formData.current) {
                        const {init, updated} = formData.current;

                        if (i_account) {
                            dispatch(
                                actions.editAutoAttendant.request({
                                    accountId: i_account,
                                    extensionId: i_c_ext,
                                    initialValues: init as EditAutoAttendantForm,
                                    changedValues: updated as EditAutoAttendantForm,
                                    redirectTab: tabIndex,
                                    blockRedirection: blockRedirection,
                                    callback: ()=>                 setIsFormSaving(false)

                                }),
                            );
                        }
                    }
                }, 0);
            } else {
                setIsFormSaving(false)
                dispatch(actions.saveForms({visible: true}))
            }
        }, 1000);
    };

    const handleOnDeleteClick = () => {
        if (extension_id && i_customer) {
            showRemoveExtensionModal(extension_id, i_customer);
        }
    };

    if (loading) {
        return (
            <div className={classes.loader}>
                <Loader dataQa="auto-attendant-loader"/>
            </div>
        );
    }

    const markTabAsHaveError = (tabName: string, tabIndex: number, setTabError: boolean) => {
        dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
    }

    const tabsNameResolver = {
        extension: t('screens:extensions.extension'),
        callSettings: t('screens:extensions.callSettings'),
        callScreening: t('screens:extensions.callScreening'),
        plan: t('screens:extensions.plan'),
        callForwarding: t('screens:extensions.callForwarding'),
    };

    const {tabNames, tabs, perm} = generateTabs(
        [
            {
                title: t('screens:autoAttendants.callFlow'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails
                    .CallFlow.value,
                tab: <CallFlowTab {...tabProps} key={'tab1'} isActive={tabIndex == 0}
                                 tabName={t('screens:autoAttendants.callFlow')}/>
            },
            {
                title: t('screens:autoAttendants.menu'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Menu
                    .value,
                tab: <MenuTab autoAttendantId={id} key={'tab2'} isActive={tabIndex == 1}
                              tabName={t('screens:autoAttendants.menu')}/>,
            },
            {
                title: t('screens:extensions.extension'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails
                    .Extension.value,
                tab: (
                    <AutoAttendantExtensionTab
                        accountId={i_account}
                        {...tabProps}
                        key={'tab3'}
                        isActive={tabIndex == 2} tabName={t('screens:extensions.extension')}
                        callback={() => {
                            markTabAsHaveError(t('screens:extensions.extension'), indexOf(tabNames, t('screens:extensions.extension')), true);
                            dispatch(actions.saveForms({visible: true}));
                        }}
                    />
                ),
            },
            {
                title: t('screens:extensions.callSettings'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails
                    .CallSettings.value,
                tab: (
                    <CallSettingsTab
                        accountId={i_account}
                        {...tabProps}
                        key={'tab4'}
                        isActive={tabIndex == 3} tabName={t('screens:extensions.callSettings')}
                    />
                ),
            },
            {
                title: t('screens:extensions.callScreening'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails
                    .CallScreening.value,
                tab: (
                    <CallScreeningTab
                        {...tabProps}
                        extensionNumber={extension_id}
                        showPBX={false}
                        forceTooltip={t('screens:incoming.callScreening')}
                        key={'tab5'}
                        isActive={tabIndex == 4} tabName={t('screens:extensions.callScreening')}
                    />
                ),
            },
            {
                title: t('screens:extensions.callForwarding'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails
                    .CallForwarding.value,
                tab: <CallForwardingTab
                    {...tabProps} key={'tab6'}
                    isActive={tabIndex == 5}
                    tabName={t('screens:extensions.callForwarding')
                    }

                />,
            },
            {
                title: t('screens:extensions.plan'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails.Plan
                    .value,
                tab: (
                    <PlanTab
                        {...tabProps}
                        productType={MainProductType.AutoAttendants}
                        key={'tab7'}
                        isActive={tabIndex == 6} tabName={t('screens:extensions.plan')}

                    />
                ),
            },
            {
                title: t('screens:calls.callHistory'),
                permission:
                Permission.CloudPBX.AutoAttendants.AutoAttendantDetails
                    .CallHistory.value,
                tab: (
                    <CallHistoryForm
                        accountId={i_account}
                        key={'tab8'}
                        downloadPermission={
                            Permission.CloudPBX.AutoAttendants
                                .AutoAttendantDetails.CallHistory
                                .CallDetailRecord.DownloadCallRecording.value
                        }
                        deletePermission={
                            Permission.CloudPBX.AutoAttendants
                                .AutoAttendantDetails.CallHistory
                                .CallDetailRecord.DeleteCallRecording.value
                        }
                        recordPermission={
                            Permission.CloudPBX.AutoAttendants
                                .AutoAttendantDetails.CallHistory
                                .CallDetailRecord.value
                        }
                    />
                ),
            },
        ],
        permissions,
    );

    const callFlowOnFirstTab = getPermission(permissions, ...Permission.CloudPBX.AutoAttendants
        .AutoAttendantDetails.CallFlow.value) !== PermissionType.Hidden;

    const showActionButtons = (indx: number) => {
        const indexOfMenuTab = tabNames.indexOf(t('screens:autoAttendants.menu'));
        return indexOfMenuTab !== indx;
    };

    const onTabChange = (_: React.ChangeEvent<{}>, index: any) => {
        dispatch(actions.saveForms({visible: false}))
        areAllFormsValid();
        setTabIndex(index);
    }

    if (apiError?.faultcode === AUTO_ATTENDANT_NOT_FOUND_API_ERROR) {
        return (
            <NotFound/>
        );
    }

    return (
        <>
            <DetailsWrapper
                showActionButtons={showActionButtons(tabIndex)}
                deleteEnable={isSupervisor ? false : delete_enable}
                deleteText={t('screens:autoAttendants.deleteAutoAttendant')}
                onBackPress={onBackPress}
                onDeletePress={handleOnDeleteClick}
                onSavePress={() => onSubmitExtensionDetails(false)}
                saveEnable={saveEnabled}
                preventIsOpen={isDirty}
                preventSavePress={() => onSubmitExtensionDetails(true)}
                top={
                    <PermissionProvider
                        permission={
                            Permission.CloudPBX.AutoAttendants
                                .AutoAttendantDetails.value
                        }
                    >
                        <DetailsHeader id={id}/>
                    </PermissionProvider>
                }
                tabs={tabs}
                tabsNames={tabNames}
                defaultTabIndex={tabIndex}
                onTabChange={onTabChange}
                callFlowOnFirstTab={callFlowOnFirstTab}
                deletePermission={
                    Permission.CloudPBX.AutoAttendants.AutoAttendantDetails
                        .DeleteAutoAttendant.value
                }
                perm={perm}
                actionsCustomClass={classes.actionsCustomClass}
            >
                {isFormSaving || deletingInProgress ? (
                    <Loader
                        dataQa="edit-auto-attendant-sending-loader"
                        absolutePosition
                    />
                ) : null}
                <AlertDialog
                    isOpen={!!removeModal?.isOpen}
                    dataQa={'delete-auto-attendant-modal'}
                    contentClass="medium-width-modal-delete"
                    hideHeader={true}
                    description={`${t(
                        'screens:autoAttendants.deleteAutoAttendant',
                    )}?`}
                    className={classes.deleteDialogButtons}
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={closeRemoveDialog}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={deleteExtension}
                        />,
                    ]}
                />
                <ErrorNotification tabName={tabNames[tabIndex]} isSubmitting={saveClicked}/>
            </DetailsWrapper>
        </>
    );
};
export default AutoAttendantDetails;
