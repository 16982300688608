import { makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import {IndividualType} from "../store/types/CustomerInfo";

export interface SidebarContextData {
    isSidebarOpen: boolean;
    toggleSidebar?: () => void;
    groupsOpenStatuses: boolean[];
    changeGroupStatus?: (index: number) => void;
    userType?:IndividualType | string
}

export const SidebarContext = React.createContext<SidebarContextData>({
    isSidebarOpen: true,
    groupsOpenStatuses: Array(10).fill(false),
});

export const menuLocalStoreKey = 'porta_one_menu';

export const useSidebarContext = () =>
    useContext<SidebarContextData>(SidebarContext);
    export const useStyles = makeStyles(() => ({
        sidebarContext: {
            width: 'fit-content',
            minWidth: 'max(1130px, 100vw)'
        }
    }));
    
export const SidebarProvider: React.FC<{userType: IndividualType | string} > = ({ userType,children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const classes = useStyles();

    let initState: boolean[] = Array(10).fill(false);
    if (localStorage.getItem(menuLocalStoreKey)) {
        initState = JSON.parse(localStorage.getItem(menuLocalStoreKey) || '');
    } else {
        localStorage.setItem(menuLocalStoreKey, JSON.stringify(initState));
    }

    const [groupsOpenStatuses, setGroupsOpenStatuses] = useState<boolean[]>(
        initState,
    );

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const changeGroupStatus = (index: number) => {
        const groupsOpenStatusesCopy = [...groupsOpenStatuses];
        groupsOpenStatusesCopy[index] = !groupsOpenStatusesCopy[index];
        setGroupsOpenStatuses(groupsOpenStatusesCopy);
    };

    return (
        <SidebarContext.Provider
            value={{
                isSidebarOpen,
                toggleSidebar,
                groupsOpenStatuses,
                changeGroupStatus,
                userType
            }}
        >
            <div className={classes.sidebarContext}>{children}</div>
        </SidebarContext.Provider>
    );
};
