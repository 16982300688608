import {useSelector} from "react-redux";
import {ReduxState} from "../../store/types";
import {IndividualType} from "../../store/types/CustomerInfo";
import {useMemo} from "react";

export const useIndividualTypeHook = () => {

    const individual_type = useSelector(
        (state: ReduxState) => state.generic.sessionData?.individual_type,
    )

    // @ts-ignore
    const {i_account,i_individual} = useSelector(
        (state: ReduxState) => state.generic.sessionData,
    )

    const isSupervisor = useMemo(() => {
        return individual_type ? individual_type == IndividualType.Supervisor : false
    }, [individual_type])


    return {
        isSupervisor,
        i_account,
        i_individual
    }
}