import { ParkingSlotsListItem } from "../../actions/callParkingSlots/payloads";
import { ActionType, createReducer } from 'typesafe-actions';
import { actions } from '../..';
import { APIErrorInterface } from "../../types";

export interface CallParkingSlotsState {
    parkingSlotsList: {
        total: number;
        data: ParkingSlotsListItem[];
    };
    isLoading: boolean;
    isDeleting?: boolean;
    addEditApiError?: APIErrorInterface;
}

export const initialState = {
    parkingSlotsList: {
        total: 0,
        data: [],
    },
    isLoading: false,
    isDeleting: false,
} as CallParkingSlotsState;

export type CallParkingSlotsActions = ActionType<typeof actions>;

export const phoneBookReducer = createReducer<CallParkingSlotsState, CallParkingSlotsActions>(
    initialState,
)
    .handleAction(actions.getCallParkingSlotsList.request, (state) => ({
        ...state,
        isLoading: true,
    }))
    .handleAction(actions.getCallParkingSlotsList.success, (state, action) => ({
        ...state,
        parkingSlotsList: action.payload,
        isLoading: false,
    }))
    .handleAction(actions.getCallParkingSlotsList.failure, (state) => ({
        ...state,
        isLoading: false,
    }))
    .handleAction(actions.deleteCallParkingSlot.request, (state) => ({
        ...state,
        isDeleting: true,
    }))
    .handleAction(actions.deleteCallParkingSlot.success, (state) => ({
        ...state,
        isDeleting: false,
    }))
    .handleAction(actions.deleteCallParkingSlot.failure, (state) => ({
        ...state,
        isDeleting: false,
    }))
    .handleAction(actions.addCallParkingSlot.request, (state) => ({
        ...state,
        addEditApiError: undefined,
    }))
    .handleAction(actions.addCallParkingSlot.failure, (state, action) => ({
        ...state,
        addEditApiError: action.payload
    }))
    .handleAction(actions.editCallParkingSlot.request, (state) => ({
        ...state,
        addEditApiError: undefined,
    }))
    .handleAction(actions.editCallParkingSlot.failure, (state, action) => ({
        ...state,
        addEditApiError: action.payload
    }))
    .handleAction(actions.cleanApiError, (state) => ({
        ...state,
        addEditApiError: undefined
    }))
    ;

export default phoneBookReducer;
