import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import PaymentMethodDetails from '../../../../components/Payments/PaymentMethodDetails/PaymentMethodDetails';
import {ReduxState} from '../../../../store/types';
import {
    OwnerPaymentMethod,
    PaymentMethodInfo,
} from '../../../../store/types/Payment';
import {useStyles} from './DefaultPaymentMethod.utils';
import {YesNo} from "../../../../store/types/CallScreening";

const SvDefaultPaymentMethod = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    const ownerPaymentsMethods = useSelector<
        ReduxState,
        OwnerPaymentMethod[] | undefined
    >((state) => state.billing.ownerPaymentsMethods);

    const paymentMethod = useSelector<
        ReduxState,
        PaymentMethodInfo | undefined
    >((state) => state.billing.customerPaymentMethod);


    return (
        <div className={classes.container}>
            <label className={classes.header}>
                {t('screens:billing.defaultPaymentMethod')}
            </label>

            {!ownerPaymentsMethods?.length && (
                <>
                    <span className={classes.noMethodLabel}>
                        {t('screens:billing.noSuitablePaymentMethod')}
                    </span>
                    <span>
                        {t('screens:billing.pleaseContactAdministrator')}
                    </span>
                </>
            )}

            {!!ownerPaymentsMethods?.length
                && ownerPaymentsMethods.filter(e => e.ext_auth === 'N').length === 0
                && (
                    <>
                    <span className={classes.noMethodLabel}>
                        {t('screens:billing.noSuitablePaymentMethod')}
                    </span>
                    </>
                )}

            {!!ownerPaymentsMethods?.length && paymentMethod && (
                <div className={classes.paymentMethodContainer}>
                    <div className={classes.methodInsidecontainer}>
                        <PaymentMethodDetails method={paymentMethod}/>
                    </div>
                </div>
            )}

            {!!ownerPaymentsMethods?.length && !paymentMethod && ownerPaymentsMethods[0].remote_cc_storage == YesNo.Yes && (
                <>
                    <span className={classes.noMethodLabel}>
                            {t('screens:billing.tokenOnlyText1')}
                    </span>
                </>
            )}
        </div>
    );
};

export default SvDefaultPaymentMethod;
