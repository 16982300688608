import React, {useEffect, useMemo} from 'react';
import { Grid, Box } from '@material-ui/core';
import IconWithTooltip from '../../Tooltip/IconWithTooltip';
import {useStyles, CreateNewPortalUserFormData, PlusIcon, portalUserSecurityFiledsName} from '../utils';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import CustomButton from '../../Button/Button';
import TextField from '../../TextField/TextField';
import { IconButton } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import classNames from 'classnames';
import { usePermissionContext } from '../../../hooks/usePermissions';
import { PermissionType } from '../../../store/types/Permission';
import CustomizedTooltip from '../../Tooltip/Tooltip';
import TwoFaSetting from '../../TwoFaSetting/TwoFaSetting';
import {useDispatch, useSelector} from 'react-redux';
import { ReduxState } from '../../../store/types';
import { TwoFaSettingProps } from '../../TwoFaSetting/TwoFaSetting.utils';
import {ErrorTabInteface} from "../../Forms/RingGroups/generalFormUtils";
import {actions} from "../../../store";
import {isAnyKeyFromSearchExistInSource} from "../../../utils/isAnyKeyFromSearchExistInSource";

const PortalUserSecurity:React.VFC<ErrorTabInteface> = ({tabName,tabIndex}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const permission = usePermissionContext();
    const dispatch = useDispatch();

    const {individualMfaConfigError, login} = useSelector((state: ReduxState) => state.auth);
    const {editedPortlUser} = useSelector((state: ReduxState) => state.company);

    const {
        values,
        errors,
        setFieldValue,
        setFieldError,
    } = useFormikContext<CreateNewPortalUserFormData>();

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    useEffect(() => {

        const errorsKeys = Object.keys(errors);
        if (isAnyKeyFromSearchExistInSource(errorsKeys, portalUserSecurityFiledsName)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }

    }, [errors]);


    const addNewIP = () => {
        if (values.ipAddresses) {
            setFieldValue('ipAddresses', [
                ...values?.ipAddresses,
                {
                    id:
                        values.ipAddresses[values.ipAddresses.length - 1]?.id +
                        1,
                    value: '',
                },
            ]);
        }
    };

    const onDelete = (id: number) => {
        if (!!values.ipAddresses && values.ipAddresses.length > 1) {
            const newAddresses = [...values.ipAddresses];
            const indexToRemove = newAddresses?.findIndex((e) => e.id === id);
            newAddresses.splice(indexToRemove, 1);
            setFieldValue(
                'ipAddresses',
                newAddresses.map((v, index) => ({ ...v, id: index })),
            );
        } else if (values?.ipAddresses?.length === 1) {
            const newValues = {
                ...values,
                ipAddresses: [
                    {
                        id: 0,
                        value: '',
                        isValueRequired: false,
                    },
                ],
            };

            setFieldValue('ipAddresses', newValues.ipAddresses);
        }
        setFieldError('ipAddresses', undefined);
    };

    const onChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        index: number,
    ) => {
        const indexToUpdate = values?.ipAddresses?.findIndex(
            (e) => e.id === index,
        );

        setFieldValue(
            `ipAddresses.[${indexToUpdate}].value`,
            event.target.value,
        );
    };

    const IPs = () => (
        <>
            {values?.ipAddresses?.map((ipElement, i) => {
                //@ts-ignore
                const error = errors?.ipAddresses?.[i]?.value;
                return (
                    <div
                        key={ipElement.id}
                        className={classes.ipElementWrapper}
                    >
                        <TextField
                            id={`ipAddresses.[${ipElement.id}].value`}
                            label={t('screens:portalUsers.ipAddress')}
                            onChange={(
                                e: React.ChangeEvent<
                                    HTMLInputElement | HTMLTextAreaElement
                                >,
                            ) => onChange(e, ipElement.id)}
                            value={ipElement.value}
                            setFieldError={setFieldError}
                            dataQa={`create-portal-user-ipAddress-${ipElement.id}`}
                            helperText={error}
                            className={classes.textField}
                            warningIcon={false}
                        />
                        {values?.ipAddresses?.length === 1 &&
                        values?.ipAddresses[0]?.value === '' ? null : 
                            (permission !== PermissionType.ReadOnly ? (
                            <IconButton
                                onClick={() => onDelete(ipElement.id)}
                                size="medium"
                                data-testid={'remove-list-item-button-' + ipElement.id}
                                data-qa="remove-list-item-button"
                            >
                                <Delete htmlColor={'#666666'} />
                            </IconButton>
                        ) : (
                            <div className={classes.disabledDeleteButtonContainer}>
                                <CustomizedTooltip
                                    copy={false}
                                    title={t('common:noPermissionToResource')}
                                >
                                <Delete htmlColor={'#D7D9DA'} 
                                    data-testid={'remove-list-item-button-disabled-' + ipElement.id} />
                                </CustomizedTooltip>
                            </div>
                        ))}
                    </div>
                );
            })}
        </>
    );

    const props2FA = useMemo(() => {
        return {
            object: 'individual',
            i_object: editedPortlUser?.customer_individual_info.i_individual || -1,
            isMyOwn: (login || '').toUpperCase() === (values?.login || '').toUpperCase(),
            login: editedPortlUser?.customer_individual_info.login || ''
        } as TwoFaSettingProps;
    }, [login, editedPortlUser, values]);

    return (
        <>
            {!individualMfaConfigError && (
                <Grid item className={classNames(classes.itemsContainer, classes.itemsContainer2Fa)}>
                    <TwoFaSetting {...props2FA} />
                </Grid>
            )}
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.ipContainer,
                )}
                data-testid={'edit-user-security'}
            >
                <Box className={classes.headerBox} width={350}>
                    <span className={classes.rowBoxHeader}>
                        {t('screens:portalUsers.allowAccessFromIP')}
                    </span>

                    <IconWithTooltip
                        dataQa="my-company-address"
                        tooltipText={t('tooltips:myCompany.ipAddress')}
                    />
                </Box>
                {IPs()}
                <CustomButton
                    primary
                    accent
                    dataQa="portaluser-add-ip"
                    onClick={addNewIP}
                    className={classes.addNewIP}
                >
                    {PlusIcon}
                    {t('screens:portalUsers.addIp')}
                </CustomButton>
            </Grid>
        </>
    );
};

export default PortalUserSecurity;
