import React, {useCallback, useState} from 'react';
import {useFormikContext} from 'formik';
import {TFunction, useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {reorder} from '../../DataTable/DragDropDataTable';
import DataItemActions from '../../DataTable/DataItemActions';
import * as Yup from 'yup';
import {Colors} from '../../../styles/Colors';
import {
    DispatchType,
    GroupMemberType,
    RingGroupMemberFormProps,
    ringGroupMemberFormValidationSchema,
} from '../../RingGroups/groupDialogUtils';
import GroupMemberDialog from '../../RingGroups/GroupMemberDialog';
import {useSelector} from 'react-redux';
import {ReduxState} from '../../../store/types';
import {RingGroupType, RingStrategy} from '../../../store/types/RingGroup';
import {ExtensionsListItem} from '../../../store/reducers/extensions/extensions/reducer';
import ExcludeGroupMemberDialog from '../../RingGroups/ExcludeGroupMemberDialog';
import {Column} from 'react-table';
import DataGrid from '../../DataGrid/DataGrid';
import {ReactComponent as Check} from '../../../assets/check.svg';
import CustomButton from '../../../components/Button/Button';
import {Add} from '@material-ui/icons';
import {PaginationMode} from '../../DataGrid/types';
import classNames from 'classnames';
import OverflowTooltip from '../../OverflowTooltip/OverflowTooltip';
import PermissionProvider from '../../PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../../store/types/Permission';
import TextButton from '../../TextButton/TextButton';
import SwitchWithLabel from '../../SwitchWithLabel/SwitchWithLabel';
import {CustomerOfficeType} from '../../../store/types/CustomerInfo';
import {useIndividualTypeHook} from "../../../hooks/agents/useIndividualTypeHook";

const PlusIcon = (
    <Add
        htmlColor={Colors.White}
        style={{height: 22, width: 22, marginRight: 10}}
    />
);
export type GroupMembersFormType = {
    members: RingGroupMemberFormProps[];
    ringStrategy?: RingStrategy;
};

export const groupMembersValidationSchema = Yup.object().shape({
    members: Yup.array().of(ringGroupMemberFormValidationSchema).notRequired(),
});

export const groupMembersDefaultValues: GroupMembersFormType = {
    members: [],
};

export type GroupMembersFormProps = {
    groupMemberDialogVisible: boolean;
    toggleMemberDialogVisible: () => void;
    masterRingGroupId?: string;
};

const useStyles = makeStyles(() => ({
    tableWrapper: {
        maxWidth: 1063,
        padding: '22px 16px 0px 16px',
    },
    numberLabel: {
        marginLeft: -60,
        marginTop: 10,
    },
    buttonsCell: {
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        alignSelf: 'center',
        alignContent: 'center',
        justifyContent: 'flex-end',
        display: 'flex',
        flex: 1,
    },
    pagination: {
        marginTop: 30,
        '& .MuiInputBase-input:first-of-type': {
            padding: '10px 40px 10px 10px !important',
        },
    },
    ignoreSwitchContainer: {
        width: 107,
        display: 'flex',
        justifyContent: 'center',
        color: Colors.Support,
    },
    supervisorSwitchContainer: {
        width: 72,
    },
    idText: {
        color: Colors.Secondary1,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    type: {
        color: Colors.Gray9,
    },
    addButton: {
        marginBottom: 24,
        marginTop: 0,
        '& .MuiButton-label': {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
        },
    },
    ringingSwitchContainer: {
        marginLeft: -5,
        paddingTop: 10,
        height: 45
    },
    actions: {
        marginLeft: -24,
    },
    supervisorActions: {
        marginLeft: "unset",
    },
}));

const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined,
    ) => void,
    openEditDialog: (index?: number) => void,
    deleteElement: (index?: number) => void,
    validateBranchOffice: (value: PermissionType) => PermissionType,
    isSupervisor?:boolean
): Column<RingGroupMemberFormProps>[] => {

    return [
        {
            Header: t<string>('common:number'),
            accessor: 'ringGroup',
            width: 1,
            Cell: function Cell(params) {
                return (
                    <PermissionProvider
                        permission={
                            Permission.CloudPBX.RingGroups.RingGroupDetails
                                .Members.MembersSettings.value
                        }
                        skipReadOnly
                        customizePermission={validateBranchOffice}
                    >
                        <TextButton
                            onClick={() => openEditDialog(params.row.index)}
                            dataQa={`ring-group-number-cell-${
                                params.row.original.id ?? params.row.index
                            }`}
                        >
                            {params.row.original.groupMemberType ===
                            GroupMemberType.Extension
                                ? params.row.original.extension?.id
                                : params.row.original.ringGroup?.id}
                        </TextButton>
                    </PermissionProvider>
                );
            },
        },
        {
            Header: t<string>('common:name'),
            accessor: 'extension',
            width: 1.5,
            Cell: function Cell(params) {
                const name =
                    params.row.original.groupMemberType ===
                    GroupMemberType.Extension
                        ? params.row.original.extension?.name
                        : params.row.original.ringGroup?.name;

                return (
                    <OverflowTooltip
                        tooltip={name}
                        text={name}
                        dataQa={`ring-group-name-cell-${
                            params.row.original.id ?? params.row.index
                        }`}
                    />
                );
            },
        },
        {
            Header: t<string>('common:type'),
            accessor: 'groupMemberType',
            width: 1,
            Cell: function Cell(params) {
                return (
                    <span
                        data-qa={`ring-group-type-cell-${
                            params.row.original.id ?? params.row.index
                        }`}
                        className={classes.type}
                    >
                        {t(
                            `enums:groupMember.${params.row.original.groupMemberType}`,
                        )}
                    </span>
                );
            },
        },
        {
            Header: t<string>('screens:addToRingGroup.ignoreFollowMe'),
            accessor: 'ignoreFollowMeVoiceMail',
            width: 1.4,
            maxWidth: 280,
            Cell: function Cell(params) {
                return params.row.original.ignoreFollowMeVoiceMail ? (
                    <div
                        className={classes.ignoreSwitchContainer}
                        data-qa="group-member-ignore-follow-me-voicemail"
                    >
                        <Check/>
                    </div>
                ) : null;
            },
        },
        {
            Header: t<string>('screens:addToRingGroup.supervisor'),
            accessor: 'supervisionAllowed',
            width: 1,
            maxWidth: 220,
            Cell: function Cell(params: any) {
                return (
                    <div
                        className={classNames(
                            classes.ignoreSwitchContainer,
                            classes.supervisorSwitchContainer,
                        )}
                    >
                        {params.row.original.supervisionAllowed === 'Y' && (
                            <Check/>
                        )}
                    </div>
                );
            },
        },
        {
            Header: t<string>('screens:ringGroups.ringing'),
            accessor: 'dispatchType',
            width: 1,
            Cell: function Cell(params) {
                return (
                    <div className={classes.ringingSwitchContainer}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.RingGroups.RingGroupDetails
                                    .Members.MembersSettings.value
                            }
                            customizePermission={validateBranchOffice}
                        >
                            <SwitchWithLabel
                                label={""}
                                field={'dispatchType'}
                                dataQa={`group-member-ringing-${
                                    params.row.original.id ?? params.row.index
                                }`}
                                value={
                                    params.row.original.dispatchType !== DispatchType.Disabled
                                }
                                setValue={(f, v) => {
                                    setFieldValue(
                                        `members.[${params.row.index}].[dispatchType]`,
                                        v
                                            ? DispatchType.PermanentlyEnabled
                                            : DispatchType.Disabled,
                                    );

                                    setFieldValue(
                                        `members.[${params.row.index}].[customThenRingsFor]`,
                                        10,
                                    );
                                }}
                                customDisabledTooltip={t('common:noPermissionToResource')}
                            />
                        </PermissionProvider>
                    </div>
                );
            },
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'id',
            width: 1.25,
            Cell: function Cell(params) {
                return (
                    <div
                        className={isSupervisor ? classes.supervisorActions : classes.actions}
                        data-qa={`ring-group-action-cell-${
                            params.row.original.id ?? params.row.index
                        }`}
                    >
                        <DataItemActions
                            onEdit={() => openEditDialog(params.row.index)}
                            onDelete={isSupervisor ? undefined : () => deleteElement(params.row.index)}
                            editPermission={
                                Permission.CloudPBX.RingGroups.RingGroupDetails
                                    .Members.MembersSettings.value
                            }
                            deletePermission={
                                Permission.CloudPBX.RingGroups.RingGroupDetails
                                    .Members.MembersSettings.value
                            }
                            skipEditPermissionReadOnly={false}
                            customizeDeletePermission={isSupervisor ? () => PermissionType.Hidden : validateBranchOffice}
                            customizeEditPermission={isSupervisor ? () => PermissionType.Visible : validateBranchOffice}
                        />
                    </div>
                );
            },
        },
    ];
};

const GroupMembersForm: React.FC<GroupMembersFormProps> = (
    {
        groupMemberDialogVisible,
        toggleMemberDialogVisible,
        masterRingGroupId,
    }) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const {isSupervisor} = useIndividualTypeHook()

    const [removeDialogVisible, setRemoveDialogVisible] = useState(false);

    const [selectedGroupMember, setSelectedGroupMember] = useState<
        RingGroupMemberFormProps | undefined
    >();
    const [selectedGroupMemberIndex, setSelectedGroupMemberIndex] = useState<
        number | undefined
    >();

    const details = useSelector<ReduxState, RingGroupType | undefined>(
        (state) => state.ringgroups.ringGroupDetails,
    );

    const groupMembersDetails = useSelector<
        ReduxState,
        { ringGroups: RingGroupType[]; extensions: ExtensionsListItem[] }
    >(
        (state) =>
            state.ringgroups.groupMembersDetails || {
                ringGroups: [],
                extensions: [],
                extensionsWithVoicemail: []
            },
    );

    const i_office_type: CustomerOfficeType = useSelector<ReduxState, CustomerOfficeType>(
        (state) => state.generic?.customerOfficeType || CustomerOfficeType.Hq
    );

    const validateBranchOffice = useCallback((value: PermissionType) => {
        if (value === PermissionType.Visible && i_office_type === CustomerOfficeType.Branch) {
            return PermissionType.ReadOnly;
        }
        return value;
    }, [i_office_type]);

    const {values, setFieldValue} = useFormikContext<GroupMembersFormType>();

    const selectedRingGroupsIds = values.members
        .filter((v) => v.groupMemberType === GroupMemberType.RingGroup)
        .map((v) => v.ringGroup?.id);
    const selectedExtensionsIds = values.members
        .filter((v) => v.groupMemberType === GroupMemberType.Extension)
        .map((v) => v.extension?.id);

    const availableRingGroups = groupMembersDetails.ringGroups.filter(
        (v) =>
            (!selectedRingGroupsIds.includes(v.id) ||
                v.id === selectedGroupMember?.ringGroup?.id) &&
            v.id !== masterRingGroupId,
    );
    const availableExtension = groupMembersDetails.extensions.filter(
        (v) =>
            !selectedExtensionsIds.includes(v.id) ||
            v.id === selectedGroupMember?.extension?.id,
    );

    const onChangeItemPosition = (startIndex: number, endIndex: number) => {
        setFieldValue('members', reorder(values.members, startIndex, endIndex));
    };

    const onChangeGroupMember = (form: RingGroupMemberFormProps) => {
        if (selectedGroupMember) {
            setFieldValue(`members.[${selectedGroupMemberIndex}]`, form);
        } else {
            const members = [...values.members, form];
            setFieldValue('members', members);
        }
        toggleMemberDialogVisible();
        setSelectedGroupMember(undefined);
        setSelectedGroupMemberIndex(undefined);
    };

    const openEditDialog = (elementIndex?: number) => {
        if (elementIndex !== undefined) {
            setSelectedGroupMember(values.members[elementIndex]);
            setSelectedGroupMemberIndex(elementIndex);
            toggleMemberDialogVisible();
        }
    };

    const openRemoveDialog = (elementIndex?: number) => {
        if (elementIndex !== undefined) {
            setSelectedGroupMember(values.members[elementIndex]);
            setSelectedGroupMemberIndex(elementIndex);
            setRemoveDialogVisible(true);
        }
    };

    const deleteItem = () => {
        setRemoveDialogVisible(false);
        setTimeout(() => {
            setFieldValue(
                'members',
                values.members.filter(
                    (_, index) => index !== selectedGroupMemberIndex,
                ),
            );
            setSelectedGroupMember(undefined);
            setSelectedGroupMemberIndex(undefined);
        }, 10);
    };

    const columns = generateColumns(
        t,
        classes,
        setFieldValue,
        openEditDialog,
        openRemoveDialog,
        validateBranchOffice,
        isSupervisor
    );


    return (
        <div className={classes.tableWrapper}>

            {!isSupervisor && (
                <PermissionProvider
                    permission={
                        Permission.CloudPBX.RingGroups.RingGroupDetails.Members
                            .AddNewMember.value
                    }
                    customizePermission={validateBranchOffice}
                >
                    <CustomButton
                        primary
                        accent
                        dataQa="followme-add-rule-button"
                        className={classes.addButton}
                        onClick={toggleMemberDialogVisible}
                    >
                        {PlusIcon}
                        {t('screens:ringGroups.newGroupMember')}
                    </CustomButton>
                </PermissionProvider>
            )}

            <DataGrid<RingGroupMemberFormProps>
                classes={{pagination: classes.pagination}}
                columns={columns}
                data={values.members.map((v) => ({
                    ...v,
                    id: v.extension?.id || v.ringGroup?.id,
                }))}
                rowCount={values.members?.length || 0}
                dragEnabled={values.ringStrategy === RingStrategy.Order}
                onChangeItemPosition={onChangeItemPosition}
                centeredRows
                paginationMode={PaginationMode.Client}
            />

            <GroupMemberDialog
                isOpen={groupMemberDialogVisible}
                ringGroups={availableRingGroups}
                extensions={availableExtension}
                currentMembers={values.members}
                toggleVisibility={() => {
                    toggleMemberDialogVisible();
                    setTimeout(setSelectedGroupMember, 200, undefined);
                    setTimeout(setSelectedGroupMemberIndex, 200, undefined);
                }}
                onSave={onChangeGroupMember}
                objectToEdit={selectedGroupMember}
                i_c_group={details?.i_c_group}
                isSupervisor={isSupervisor}
            />

            <ExcludeGroupMemberDialog
                isOpen={removeDialogVisible}
                toggleVisibility={() => {
                    setRemoveDialogVisible(!removeDialogVisible);
                    setTimeout(() => {
                        setSelectedGroupMember(undefined);
                        setSelectedGroupMemberIndex(undefined);
                    }, 10);
                }}
                type={selectedGroupMember?.groupMemberType}
                dataObj={
                    selectedGroupMember?.groupMemberType ===
                    GroupMemberType.Extension
                        ? selectedGroupMember?.extension
                        : selectedGroupMember?.ringGroup
                }
                onClickYesButton={deleteItem}
            />
        </div>
    );
};

export default GroupMembersForm;
