import React from 'react';
import {makeStyles} from '@material-ui/core';
import {Refresh, Add} from '@material-ui/icons';
import i18n from '../../services/i18n';
import * as Yup from 'yup';
import {Colors} from '../../styles/Colors';
import {Netmask} from 'netmask';
import {ExtensionType} from "../../store/types/Extension";

export const PlusIcon = (
    <Add
        htmlColor={Colors.White}
        style={{height: 20, width: 20, marginRight: 6, marginLeft: '-4px'}}
    />
);

export const RefreshIcon = <Refresh htmlColor={Colors.Gray5}/>;

export const requiredFieldError = i18n.t('errors:common.emptyInput');
export const nameMaxLengthError = i18n.t('errors:portalUser.max32Length');
export const emailValidError = i18n.t('errors:extensions.emailAddress');
export const ipAddressInvalidFormat = i18n.t(
    'errors:portalUsers.invalidFormat',
);
export const ipAddressEmpty = i18n.t('errors:portalUsers.emptyValue');
export const duplication = i18n.t('errors:portalUsers.duplication');

function isValidIp(value: string, lastBoardMaxVal = 1) {
    const regex = /^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/;

    if (regex.test(value || '')) {

        const split = value?.split('.');
        let isValid = true;
        split?.forEach((splitVal, index) => {
            if (index == 0 && Number(splitVal) < 1 || Number(splitVal) > 255) {
                isValid = false;
            } else if (index == 3 && Number(splitVal) < lastBoardMaxVal || Number(splitVal) > 255) {
                isValid = false;
            } else if (Number(splitVal) < 0 || Number(splitVal) > 255) {
                isValid = false;
            }
        });

        return isValid;
    }

    return false;
}

export const SupervisorValidationSchema = Yup.object().shape({
    extension: Yup.object().when('type', {
        is: 'hunt_group_supervisor',
        then: Yup.object().required(requiredFieldError)
    }).typeError(requiredFieldError)
});

export const CreateProfileSupervisorValidationSchema = Yup.object().shape({
    extension: Yup.object().required(requiredFieldError)
});

export const SecurityValidationSchema = Yup.object().shape({
    ipAddresses: Yup.array().of(
        Yup.object().shape({
            isValueRequired: Yup.boolean(),
            value: Yup.string()
                .test('duplication', duplication, function (value) {
                    const result = this?.options?.context?.ipAddresses;

                    for (let i = 0; i < this.parent.id; i++) {
                        if (result[i].value === value) {
                            return false;
                        }
                    }

                    return true;
                })
                .test('ip', ipAddressInvalidFormat, function (value) {
                    if (
                        this.options?.context?.ipAddresses.length === 1 &&
                        value === ''
                    ) {
                        return true;
                    }

                    if (value === '') {
                        return this.createError({
                            path: this.path,
                            message: ipAddressEmpty,
                        });
                    }

                    if (value?.includes('/')) {
                        const values = value.split('/');
                        if (
                            !(
                                isValidIp(values[0], 0) &&
                                (isValidIp(values[1]) ||
                                    /[1-3][0-9]{0,1}/.test(values[1]))
                            )
                        ) {
                            return false;
                        }
                    } else {
                        if (!isValidIp(value || '')) {
                            return false;
                        }
                    }

                    try {
                        new Netmask(value || '');
                        return true;
                    } catch (e) {
                        return false;
                    }
                }),
        }),
    ),
});

export const portalUserSettingsFieldsName = [
    'login',
    'password',
    'email',
    'role',
    'activationDate',
    'expirationDate',
    'timezone',
    'language',
    'extension'
];

export const portalUserSecurityFiledsName = [
    'ipAddresses'
]

export interface CreateNewPortalUserFormData {
    login: string;
    password: string;
    email?: string;
    role: string;
    activationDate?: Date | string | null;
    expirationDate?: Date | string | null;
    timezone?: string;
    language?: string;
    ipAddresses?: ipAddress[];
    extension?: ExtensionType
}

export type ipAddress = {
    value: string;
    id: number;
};

export type MappedValuesPortalUserType = {
    login: string;
    password: string;
    email?: string;
    i_role: string;
    activation_date?: Date | null | string;
    expiration_date?: Date | null | string;
    i_time_zone?: string;
    lang?: string;
    login_allowed_ip_list?: ipAddress[];
    i_account?: number
};

export const mappedValuesPortalUser = {
    login: 'login',
    password: 'password',
    email: 'email',
    role: 'i_role',
    activationDate: 'activation_date',
    expirationDate: 'expiration_date',
    timezone: 'i_time_zone',
    language: 'lang',
    ipAddresses: 'login_allowed_ip_list',
    extension: 'i_account'
};

export interface CreateNewExtensionDialogProps {
    isOpen: boolean;
    toggleVisibility?: () => void;
}

export const useStyles = makeStyles(() => ({
    extensionField: {},
    itemsContainer: {
        padding: '22px 16px 22px 16px',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 24,
        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
        },

        '& > :first-child': {
            marginTop: 0,
        },
        '& .MuiInputBase-input': {
            padding: '15px 10px 0 16px !important',
        },
    },
    inputs: {
        '& .MuiFormControl-root:first-of-type': {
            marginRight: 90,
        },
    },
    sectionMargin: {
        marginTop: 16,
    },
    visibilityIcon: {
        marginRight: 6,
    },
    primaryActionButton: {
        width: 96,
        background: Colors.LightGraySecondary4,
        '& .MuiButton-label': {
            color: Colors.Gray9,
        },
    },
    saveButton: {
        background: Colors.Primary,
        width: 96,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: `${Colors.White} !important`,
        },
        '&:hover': {
            cursor: 'pointer',
            background: Colors.Primary,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },

        '& .MuiButtonBase-root': {
            width: 120,
        },
    },
    modalContainer: {
        '& .MuiPaper-root': {
            width: 1080,
        },
        '& .MuiDialogContent-root': {
            minWidth: 600,
            backgroundColor: Colors.SmokeBackground,
            padding: '28px 60px',
            '& .MuiInputBase-root': {
                height: 56,
                maxWidth: 440,
            },
            '& .MuiFormControl-root': {
                maxWidth: 440,
            },
        },
    },
    timeZoneRow: {
        '& > :first-child': {
            maxWidth: 440,
        },
    },
    customOptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    customOptionSemiValue: {
        fontSize: 14,
        color: Colors.Gray,
    },
    option: {
        height: '48px !important',
    },
    tooltipIcon: {
        marginLeft: 10,
    },
    rowBox: {
        padding: 0 + '!important',
        display: 'flex',

        marginTop: 24,
        '& > :first-child': {
            marginRight: 37,
            maxWidth: 440,
        },
        '& > :last-child': {
            maxWidth: 440,
        },
    },
    rowBoxSecond: {
        '& > :first-child': {
            marginRight: 37,
        },
    },
    readOnlyRole: {
        '& .MuiAutocomplete-endAdornment': {
            '& button': {
                display: 'none'
            }
        }
    },
    readOnlyRoleIconContainer: {
        '& svg': {
            '& path': {
                fill: `${Colors.Black}`,
                fillOpacity: 0.6
            }
        }
    },
    headerBox: {
        display: 'flex',
        marginTop: 0,
        padding: 0 + '!important',
        fontWeight: 500,
    },
    rowBoxHeader: {
        marginRight: 12,
        fontSize: 16,
    },
    textField: {
        maxWidth: 440,

        margin: '16px 17px 0 0',
        '& > :last-child': {
            marginBottom: 0,
        },
    },
    ipElementWrapper: {
        marginTop: 7,
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',

        '& .MuiButtonBase-root': {
            marginTop: 18,
        },
    },
    addNewIP: {
        height: 40,
        whiteSpace: 'nowrap',
        marginTop: 24,
    },
    smallerMargin: {
        marginTop: 18,
    },
    marginBottom: {
        marginBottom: 10,
    },
    marginNone: {
        marginTop: 0,
        paddingTop: 6,
    },
    ipContainer: {
        alignItems: 'flex-start',
    },
    disabledDeleteButtonContainer: {
        marginTop: 26,
        marginLeft: 15
    },
    itemsContainer2Fa: {
        paddingTop: 46,
        paddingBottom: 12,
        paddingLeft: 16,
        borderBottom: '1px solid ' + Colors.Border,
        marginBottom: 0
    },
}));
