import React from "react";
import {ReactComponent as WhisperIcon} from '../../assets/call_control_icons/whisper.svg';
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";
import {useTranslation} from "react-i18next";
import CustomizedTooltip from "../Tooltip/Tooltip";
import {AgentCallControlsProps} from "./callControls.utils";
import {useDispatch} from "react-redux";
import {actions} from "../../store";
import classNames from "classnames";
import {SipCallState} from "../../store/actions/ringgroups/payloads";
import {YesNo} from "../../store/types/CallScreening";


const useStyles = makeStyles(() => ({
    root: {
        '& path, & rect': {transition: 'fill .4s ease'},
        cursor: 'pointer',

    },

    icon: {
        '& > rect': {
            fill: Colors.White
        },
        '& >  path': {
            fill: Colors.Link
        },
        '&:hover > rect': {
            fill: Colors.Link
        },
        '&:hover  path': {
            fill: Colors.White
        },
    },

    disabled: {
        '& > rect': {
            fill: 'transparent'
        },

        '& path': {
            fill: Colors.Gray6
        },
    }


}))


const Whisper: React.VFC<AgentCallControlsProps> = (
    {
        activeCallEntity,
        callbackAfterOnClick,
        isReadOnly,
        participantId,
        call
    }) => {

    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const disabled = isReadOnly;

    const onClick = () => {
        dispatch(actions.agentJoinToActiveCall.request({
            call_info: {id: call.id, tag: call.tag},
            participant_info: {
                participant_id: participantId,
                mute: YesNo.No,
                coach: YesNo.Yes
            },
            conference_info: {
                name: call.id,
                owner_id: call.tag
            },
            callback: callbackAfterOnClick ? () => {
                setTimeout(() => {
                    callbackAfterOnClick()
                }, 500)
            } : undefined
        }))
    }

    return (
        activeCallEntity.state == SipCallState.Holding ? <></> :
            <CustomizedTooltip
                title={disabled ? '' : t('screens:calls.whisper')}
                copy={false}
                disableHoverListener={isReadOnly}
            >
                <WhisperIcon
                    className={classNames(classes.root, disabled ? classes.disabled : classes.icon)}
                    onClick={!disabled ? onClick : undefined}
                />
            </CustomizedTooltip>
    )
}

export default Whisper;