import RingGroupsSection from "../Dashboard/RingGroupsSection";
import * as React from "react";
import {useTranslation} from "react-i18next";

const RingGroupsTab = () => {

    const {t} = useTranslation()

    return(
        <div style={{maxWidth:1024}}>
            <RingGroupsSection
                showTitle={false}
                customThNames={[t('screens:ringGroups.ringGroups')]}
                showRefresh
                useInternalLoader={false}
            />
        </div>

    )

}

export default RingGroupsTab