import { createAsyncAction, createAction } from 'typesafe-actions';
import * as payloads from './payloads';
import { APIErrorInterface } from '../../types';

export const getCallParkingSlotsList = createAsyncAction(
    'GET_CALL_PARKING_SLOTS_LIST',
    'GET_CALL_PARKING_SLOTS_LIST_SUCCESSED',
    'GET_CALL_PARKING_SLOTS_LIST_FAILED',
)<
    payloads.CallParkingSlotRequestPayload,
    payloads.CallParkingSlotResponsePayload,
    APIErrorInterface
>();

export const deleteCallParkingSlot = createAsyncAction(
    'DELETE_PARKING_SLOT',
    'DELETE_PARKING_SLOT_SUCCESSED',
    'DELETE_PARKING_SLOT_FAILED',
)<
    payloads.DeleteRequestPayload,
    undefined,
    APIErrorInterface
>();

export const addCallParkingSlot = createAsyncAction(
    'ADD_CALL_PARKING_SLOT',
    'ADD_CALL_PARKING_SLOT_SUCCESSED',
    'ADD_CALL_PARKING_SLOT_FAILED',
)<
    payloads.AddCallParkingSlotRequestPayload,
    undefined,
    APIErrorInterface
>();

export const editCallParkingSlot = createAsyncAction(
    'EDIT_CALL_PARKING_SLOT',
    'EDIT_CALL_PARKING_SLOT_SUCCESSED',
    'EDIT_CALL_PARKING_SLOT_FAILED',
)<
    payloads.EditCallParkingSlotRequestPayload,
    undefined,
    APIErrorInterface
>();

export const getCallSettingsDetailsForPakingSlots = createAsyncAction(
    'GET_CALL_SETTINGS_FOR_PARKING_SLOT_DETAILS',
    'GET_CALL_SETTINGS_FOR_PARKING_SLOT_DETAILS_SUCCESS',
    'GET_CALL_SETTINGS_FOR_PARKING_SLOT_DETAILS_FAILED',
)<undefined, undefined, undefined>();

export const cleanApiError = createAction(
    'CLEAN_PARKING_SLOT_API_ERROR'
)<undefined>();