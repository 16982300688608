import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Button from '../../components/Button/Button';
import {actions} from '../../store';
import {generateColumns, useStyles, PortalUserListItem, svGenerateColumns} from './utils';
import DataGrid from '../../components/DataGrid/DataGrid';
import {ReduxState} from '../../store/types';
import SelectField from '../../components/SelectField/SelectField';
import {RoleUserItem} from '../../store/actions/company/payloads';
import CreateNewPortalUserDialog from './CreateNewPortalUserDialog';
import history from '../../history';
import Header from '../../components/ListViewHeader/Header';
import Loader from '../../components/Loader/Loader';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import {PaginationMode} from '../../components/DataGrid/types';
import usePageTitle from '../../hooks/usePageTitle';
import {Permission} from "../../store/types/Permission";
import PermissionPlaceholder from "../../components/PermissionProvider/PermissionPlaceholder";
import PermissionProvider from "../../components/PermissionProvider/PermissionProvider";
import {getRoute, Routes} from "../../routes/routes";
import {useIndividualTypeHook} from "../../hooks/agents/useIndividualTypeHook";

const PortalUsersDetails: React.VFC = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    usePageTitle();
    const {isSupervisor, i_account} = useIndividualTypeHook()

    const [roleFilter, setRoleFilter] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [forceFirstPage, setForceFirstPage] = useState(false);
    const [, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [rowToDelete, setRowToDelete] = useState<PortalUserListItem | null>(
        null,
    );

    const {portalUserList, isLoading, roleList} = useSelector(
        (state: ReduxState) => state.company,
    );

    const iTimeZone = useSelector(
        (state: ReduxState) => state.myProfile?.customer?.iTimeZone,
    );

    const timeZonesList = useSelector(
        (state: ReduxState) => state.generic?.timeZonesList ?? [],
    );

    const currentUserTimeZoneName = useMemo(() => {
        return timeZonesList?.find(e => e.i_time_zone === iTimeZone)?.time_zone_name
            ?? 'Europe/Kyiv';
    }, [iTimeZone, timeZonesList]);

    const fetchData = (params?: {
        pageSize: number;
        page: number;
        role?: string;
    }) => {
        let i_role = null;
        if (params?.role) {
            i_role = roleList?.find((e: RoleUserItem) => e.name === roleFilter)
                ?.i_role;
        }
        dispatch(
            actions.getPortalUsersList.request({
                params: {
                    limit: params ? params.pageSize : 10,
                    offset: params ? params.page * params.pageSize : 0,
                    i_role: i_role,
                    get_total: '1',
                },
            }),
        );
    };

    useEffect(() => {
        dispatch(
            actions.getPortalDetails.request({
                params: {
                    limit: 10,
                    get_total: '1',
                },
            }),
        );
    }, []);

    const onEdit = (row: PortalUserListItem) => {
        history.push(
            getRoute(Routes.EditPortalUser, {id: row.id}),
        )
    };

    const onDelete = (row: PortalUserListItem) => {
        setRowToDelete(row);
        setIsRemoveModalOpen(true);
    };

    const removePortalUser = () => {
        dispatch(
            actions.deletePortalUser.request({
                i_individual: rowToDelete?.id || 0,
                callback: () => {
                    setIsRemoveModalOpen(false);
                    onSearch();
                }
            }),
        );
    };

    const onSearch = () => {
        setForceFirstPage(true);
        setPage(0);
        fetchData({
            pageSize: pageSize,
            page: 0,
            role: roleFilter,
        });
    };

    const handleChangeVisibilityCreateNewModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        const role = roleList?.find((e: RoleUserItem) => e.name === t('common:any'));
        role && setRoleFilter(role?.name);
    }, [roleList]);

    const columns =
        isSupervisor ? svGenerateColumns(
                t,
                onEdit,
                onDelete,
                classes,
                i_account,
                currentUserTimeZoneName,
                timeZonesList,
                'YYYY-MM-DD'
            ) :
            generateColumns(
                t,
                onEdit,
                onDelete,
                classes,
                roleList,
                currentUserTimeZoneName,
                timeZonesList,
                'YYYY-MM-DD'
            );

    return (
        <PermissionPlaceholder
            permission={Permission.MyCompany.PortalUsers.value}
        >
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.header}>
                        <PermissionProvider
                            permission={
                                Permission.MyCompany.PortalUsers.AddNewPortalUser.value
                            }
                        >
                            <Header
                                title={t('screens:portalUsers.portalUsers')}
                                totalNumber={portalUserList?.allTotal ?? 0}
                                showDetails={false}
                                dataQa={'portalUserList-list-view-header'}
                                buttonDataQa={'add-new-user'}
                                buttonOnClick={handleChangeVisibilityCreateNewModal}
                                buttonText={t('common:addNewButton')}
                                plusIcon={true}
                            />
                        </PermissionProvider>

                    </div>


                    {
                        !isSupervisor && [] ? (
                            <div className={classes.filters}>
                                <form
                                    className={classes.filtersContainer}
                                    onSubmit={onSearch}
                                >
                                    <SelectField
                                        onChange={(e, value) => setRoleFilter(value)}
                                        label={t('screens:portalUsers.role')}
                                        items={roleList?.map((v) => v.name) || []}
                                        value={roleFilter}
                                        dataQa="role-portal-user"
                                        className={classes.roleSelect}
                                        disableClearable
                                    />
                                    <Button
                                        primary
                                        accent
                                        dataQa="call-history-search"
                                        className={classes.buttonSearch}
                                        onClick={onSearch}
                                    >
                                        {t('common:search')}
                                    </Button>
                                </form>
                            </div>
                        ) : <></>
                    }
                    <DataGrid<PortalUserListItem>
                        columns={columns}
                        data={portalUserList?.items || []}
                        rowCount={portalUserList?.total ?? 0}
                        loading={isLoading}
                        onPageChange={(params) => {
                            setForceFirstPage(false);
                            fetchData({
                                pageSize: params.pageSize,
                                page: params.page,
                                role: roleFilter,
                            });
                            setPage(params.page);
                        }}
                        onPageSizeChange={(params) => {
                            setForceFirstPage(false);
                            setPageSize(params.pageSize);
                            setPage(0);
                            fetchData({
                                pageSize: params.pageSize,
                                page: 0,
                                role: roleFilter,
                            });
                        }}
                        centeredRows
                        narrowRows
                        className={!isSupervisor ? classes.table : classes.svTable}
                        classes={{tableContainer:isSupervisor ? classes.svTableContainer : undefined}}
                        initialPageSize={10}
                        paginationMode={PaginationMode.Server}
                        forceFirstPage={forceFirstPage}
                        dataQa={'user-table'}
                    />

                    <AlertDialog
                        isOpen={isRemoveModalOpen}
                        dataQa="remove-ringgroup-modal"
                        contentClass="medium-width-modal-delete"
                        hideHeader={true}
                        description={t('screens:portalUsers.deletePortalUser', {
                            name: rowToDelete?.login || '',
                        })}
                        dialogActionsButtons={[
                            <DialogButton
                                key="cancel"
                                label={t('common:cancel')}
                                onClick={() => setIsRemoveModalOpen(false)}
                            />,
                            <DialogButton
                                key="delete"
                                label={t('common:delete')}
                                className={classes.primaryModalButton}
                                onClick={removePortalUser}
                            />,
                        ]}
                    />

                    <CreateNewPortalUserDialog
                        isOpen={isModalOpen}
                        toggleVisibility={handleChangeVisibilityCreateNewModal}
                    />

                    {isLoading && (
                        <Loader
                            dataQa="edit-ring-group-sending-loader"
                            absolutePosition
                        />
                    )}
                </div>
            </div>
        </PermissionPlaceholder>
    );
};

export default PortalUsersDetails;
