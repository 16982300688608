import React, { useEffect, useState } from 'react';
import {myProfileSettingsValidationReqFileName, useStyles} from './utils';
import { Box, Grid } from '@material-ui/core';
import classNames from 'classnames';
import IconWithTooltip from '../../../Tooltip/IconWithTooltip';
import { useTranslation } from 'react-i18next';
import TextField from '../../../TextField/TextField';
import SelectField from '../../../SelectField/SelectField';
import { LocaleLanguages, TimeZone } from '../../../../store/types/TimeZone';
import { useFormikContext } from 'formik';
import { EditMyProfileSettingsForm } from '../../../../views/MyProfile/utils';

import {useDispatch, useSelector} from 'react-redux';
import { ReduxState } from '../../../../store/types';
import { convertFormatFromBackendToDayJs } from '../../../../utils/dateWithTimezoneConversion';
import dayjs from '../../../../services/customDayJs';
import { Avatar } from '../../../LogoutMenuItem/Avatar';
import * as crypto from 'crypto';
import { OpenInNew } from '@material-ui/icons';
import { Colors } from '../../../../styles/Colors';
import {ErrorTabInteface} from "../../RingGroups/generalFormUtils";
import {actions} from "../../../../store";
import {isAnyKeyFromSearchExistInSource} from "../../../../utils/isAnyKeyFromSearchExistInSource";

export type ProfileSettingsProps = {
    timeZonesList: TimeZone[];
    languagesList: LocaleLanguages[];
    timeInUserFormat: string;
} & ErrorTabInteface;

const ProfileSettings: React.VFC<ProfileSettingsProps> = ({
    timeZonesList,
    languagesList,
    timeInUserFormat,
    tabIndex,
    tabName
}) => {
    const [emailState, setEmailState] = useState('');
    const [dateTimeFormatValue, setDateTimeFormatValue] = useState(
        timeInUserFormat,
    );

    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userDateTimeFormat = useSelector<ReduxState, string>(
        (state) => state.generic.sessionData?.out_date_time_format || '',
    );
    const timezoneOffset = useSelector<ReduxState, number>(
        (state) => state.generic.sessionData?.tz_offset || 0,
    );

    const user = useSelector(
        (state: ReduxState) => state.generic.sessionData?.user,
    );
    const email = useSelector(
        (state: ReduxState) => state.generic.sessionData?.email,
    );
    const emailMd5 = crypto.createHash('md5').update(emailState).digest('hex');

    const customerEmail = useSelector(
        (state: ReduxState) => state.myProfile.customer?.email,
    );

    const {
        values,
        setFieldValue,
        handleChange,
        errors,
        setFieldError,
    } = useFormikContext<EditMyProfileSettingsForm>();

    const markTabAsHaveError = (setTabError: boolean) => {
        if (tabName && tabIndex !== undefined) {
            dispatch(actions.setErrorInTab({name: tabName, index: tabIndex, markAsInvalid: setTabError}))
        }
    }

    useEffect(() => {

        const errorsKeys = Object.keys(errors);
        if (isAnyKeyFromSearchExistInSource(errorsKeys, myProfileSettingsValidationReqFileName)) {
            markTabAsHaveError(true)
        } else {
            markTabAsHaveError(false)
        }

    }, [errors,values]);

    const handleChangeTimeZone = (
        event: React.ChangeEvent<{}>,
        newValue: any,
    ) => setFieldValue('timeZone', newValue);

    const handleChangeLanguage = (
        event: React.ChangeEvent<{}>,
        newValue: any,
    ) => setFieldValue('language', newValue);

    useEffect(() => {
        const interval = setInterval(() => {
            if (timeInUserFormat) {
                const value = dayjs
                    .utc(dayjs())
                    .utcOffset(timezoneOffset / 60)
                    .format(
                        convertFormatFromBackendToDayJs(userDateTimeFormat),
                    );
                setDateTimeFormatValue(value);
            }
        }, 1000);
        return () => clearInterval(interval);
    });

    useEffect(() => {
        if (customerEmail?.length) {
            setEmailState(customerEmail);
            localStorage.setItem('user_email', customerEmail);
        } else if (email?.length) {
            setEmailState(email);
            localStorage.setItem('user_email', email);
        } else if (localStorage.getItem('user_email')) {
            setEmailState(localStorage.getItem('user_email') || '');
        }
        if(user?.length) {
            localStorage.setItem('user_login', user);
        }
    }, [email, customerEmail]);

    return (
        <div className={classes.inputs} data-qa="settings-profile-container">
            <Grid item className={classes.itemsContainer}>
                <Box
                    className={classNames(
                        classes.rowBox,
                        classes.rowBoxHeaderMargin,
                    )}
                >
                    <span
                        className={classNames(
                            classes.rowBoxHeader,
                            classes.boldHeader,
                        )}
                    >
                        {t('screens:myProfile.general')}
                    </span>

                    <IconWithTooltip
                        dataQa="my-profile-settings-genera"
                        tooltipText={t('tooltips:myProfile.settings')}
                    />
                </Box>
                <Box className={classes.rowBox}>
                    <TextField
                        id="email"
                        label={t('common:emailAddress')}
                        value={values.email}
                        onChange={handleChange}
                        helperText={errors.email}
                        setFieldError={setFieldError}
                        maxLength={128}
                    />
                    <SelectField
                        id="profileLanguage"
                        label={t('screens:myProfile.language')}
                        items={languagesList}
                        getOptionLabel={(lang) => lang.name}
                        getOptionSelected={(lang: { iso_639_1: string }) =>
                            lang.iso_639_1 === values.language?.iso_639_1
                        }
                        onChange={handleChangeLanguage}
                        value={
                            languagesList.find(
                                (lang) => lang === values.language,
                            ) || null
                        }
                    />
                </Box>
            </Grid>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.paddingTop,
                )}
            >
                <Box className={classes.rowBox}>
                    <SelectField
                        classes={{ container: classes.additionalMargin }}
                        id="timeZone"
                        label={t('screens:myProfile.timezone')}
                        items={timeZonesList}
                        getOptionLabel={(o: { time_zone_name: string }) =>
                            o.time_zone_name
                        }
                        getOptionSelected={(o: { i_time_zone: number }) =>
                            o.i_time_zone == values.timeZone?.i_time_zone
                        }
                        onChange={handleChangeTimeZone}
                        value={
                            timeZonesList.find(
                                (tz) => tz === values.timeZone,
                            ) || null
                        }
                    />
                    <TextField
                        label={t('screens:myProfile.dateTimeFormat')}
                        value={dateTimeFormatValue}
                        dataQa={'my-profile-date-time-format'}
                        readOnly={true}
                    />
                </Box>
            </Grid>
            <Grid
                item
                className={classNames(
                    classes.itemsContainer,
                    classes.paddingTop,
                )}
            >
                <Box className={classNames(classes.rowBox, classes.avatarBox)}>
                    <Avatar
                        name={user || ''}
                        emailMd5={emailMd5}
                        classes={classes.avatar}
                    />
                    <Box
                        className={classNames(
                            classes.rowBox,
                            classes.avatarWrapper,
                        )}
                    >
                        <a
                            href={'https://gravatar.com'}
                            rel="noreferrer"
                            target="_blank"
                            className={classes.avatarLink}
                        >
                            <span className={classes.changeAvatar}>
                                {t('screens:myProfile.changeAvatar')}
                            </span>
                            <OpenInNew htmlColor={Colors.Secondary1} />
                        </a>
                    </Box>
                </Box>
            </Grid>
        </div>
    );
};

export default ProfileSettings;
