import React from 'react';
import { makeStyles, TableRow, TableCell } from '@material-ui/core';
import { RowProps } from './types';
import RowCell from './RowCell';
import CustomizedTooltip from '../Tooltip/Tooltip';
import { Draggable } from 'react-beautiful-dnd';
import { DragIndicator } from '@material-ui/icons';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        flex: 1,
        backgroundColor: Colors.White,
    },
    dragCell: {
        width: 90,
        display: 'flex',
        alignItems: 'center',
        padding: 'inherit',
        justifyContent: 'center',
    },
    gray:{
        backgroundColor:Colors.GrayGroupRow

    }
}));

const Row: <T extends Object>(
    p: RowProps<T>,
) => React.ReactElement<RowProps<T>> = ({
    row,
    dragEnabled,
    index,
    onCellHover,
    renderRowHeader,
    customRowHeight,
    dragColumnStyle,
    internalHeader = false,
    customClass,
    isGroupRow = false
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Draggable
            draggableId={'draggable-item' + index}
            index={index}
            isDragDisabled={!dragEnabled}
        >
            {(provided) => (
                <React.Fragment>
                    {renderRowHeader && (
                        <TableRow className={classes.container}>
                            <TableCell>{renderRowHeader(row, index)}</TableCell>
                        </TableRow>
                    )}

                    <TableRow
                        className={classNames(classes.container, customClass?.(row, index), isGroupRow && classes.gray)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        data-id={row.id}
                        style={{
                            height: customRowHeight,
                            ...provided.draggableProps.style,
                        }}
                    >
                        {dragEnabled && (
                            <td
                                data-qa="drag-cell"
                                className={classNames(
                                    classes.dragCell,
                                    'drag-cell',
                                    dragColumnStyle,
                                )}
                                {...provided.dragHandleProps}
                            >
                                <CustomizedTooltip
                                    interactive
                                    title={t('tooltips:extensions.changeOrder')}
                                    copy={false}
                                >
                                    <DragIndicator
                                        htmlColor={Colors.Gray5}
                                        style={{ marginLeft: 10 }}
                                    />
                                </CustomizedTooltip>
                            </td>
                        )}
                        {row.cells.map((cell, index) => (
                            <RowCell
                                key={index}
                                cell={cell}
                                onCellHover={onCellHover}
                                rowIndex={row.index}
                                colIndex={index}
                                data-field={cell.column.id}
                                internalHeader={internalHeader}
                            />
                        ))}
                    </TableRow>
                </React.Fragment>
            )}
        </Draggable>
    );
};

export default Row;
