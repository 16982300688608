import axios from 'axios';

import { config } from '../config';
import { toast } from 'react-hot-toast';
import i18n from '../../services/i18n';
import camelCase from 'camelcase';

export const api = axios.create();

export const PAYLOAD_KEY = 'params=';
export const CONTENT_TYPE = 'application/x-www-form-urlencoded';

export const CHECK_AUTH_FAILD_CODE = 'Server.Session.check_auth.auth_failed';
export const CHECK_CSRF_TOKEN_INVALID_CODE = 'Server.Session.check_auth.csrf_token_mismatch';
export const CHECK_CSRF_TOKEN_MISSING_CODE = 'Server.Session.check_auth.csrf_token_missing';
export const CHECK_YOU_MUST_CHANGE_PASSWORD = 'Server.Session.alert_You_must_change_password';

export const setLogoutErrorVisible = (value = true) => {
    localStorage.setItem('session_error_visible', value ? 'true' : 'false');
};

export const isLogoutErrorVisible =() => localStorage.getItem('session_error_visible') === 'true';

const logoutGuardFunc = () => localStorage.getItem('session_expired') === 'true';
const setSessionExpired = (value = true) => {
    localStorage.setItem('session_expired', value ? 'true' : 'false');
};

export const clearStorageDatBeforeLogout = () =>
{
    localStorage.removeItem('session_id');
    localStorage.removeItem('user_info');
    localStorage.removeItem('user_email');
    localStorage.removeItem('csrf_token');
    localStorage.removeItem('default_portal_view_configuration');
    localStorage.removeItem('porta_one_menu');
    localStorage.removeItem('user_login');
}

export const logOut = (delay = 3000, reload = true) => {

    clearStorageDatBeforeLogout();

    if(reload)
    {
        setTimeout(() => {
            window.location.reload();
            setSessionExpired(false);
        }, delay);
    }

};

api.interceptors.request.use(
    (axiosConfig) => ({
        ...axiosConfig,
        baseURL: config?.baseURL as string | undefined,
    }),
    (error) => Promise.reject(error),
);

api.interceptors.response.use(undefined, (error) => {

    const randomInterval = (min: number, max: number) => { 
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const logoutGuard = logoutGuardFunc();

    if (
        (error.response?.data.faultcode == CHECK_AUTH_FAILD_CODE ||
            error.response?.data.faultcode == CHECK_CSRF_TOKEN_INVALID_CODE ||
            error.response?.data.faultcode == CHECK_CSRF_TOKEN_MISSING_CODE ||
            error.response?.data.faultcode == CHECK_AUTH_FAILD_CODE
        ) && !logoutGuard
    ) {
        const camelFaultCode = camelCase(CHECK_AUTH_FAILD_CODE);
        setTimeout(() => {
            if(!logoutGuardFunc() && !isLogoutErrorVisible()) {
                setLogoutErrorVisible(true)
                toast(i18n.t<string>('common:' + camelFaultCode));
            }
            setSessionExpired();
            logOut();
        }, randomInterval(1, 20));
    }
    if (error.response?.status == 500 &&
        error.response?.data.faultcode == CHECK_YOU_MUST_CHANGE_PASSWORD &&
        !logoutGuard
    ) {
        setSessionExpired();
        if(localStorage.getItem('user_login_page') !== 'true')
        {
            logOut();
        }
    }

    return Promise.reject(error);
});

export function buildPayload(data: any): string {
    return PAYLOAD_KEY + encodeURIComponent(JSON.stringify(data));
}
