import { makeStyles } from "@material-ui/core";
import { Colors } from "../../styles/Colors";
import { ParkingSlotsListItem } from "../../store/actions/callParkingSlots/payloads";
import * as Yup from 'yup';
import i18n from "../../services/i18n";

export type EditCallParkingSlotDialogProps = {
    isOpen: boolean,
    editedRow?: ParkingSlotsListItem,
    toggleVisibility?: (isSaved: boolean) => void,
};

export const useStyles = makeStyles(() => ({
    itemsContainer: {
        padding: '16px 24px',
        margin: '16px 24px',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormControl-root': {
            flex: 1,
            height: 'auto',
            marginTop: 32,
        },

        '& > :first-child': {
            marginTop: '0px !important',
        },

        '& .MuiInputBase-root': {
            width: 472,
        },
    },
    visibilityIcon: {
        marginRight: 6,
    },

    primaryActionButton: {
        width: 96,
        '& .MuiButton-label': {
            color: Colors.Primary,
        },
    },

    saveButton: {
        width: 96,
        backgroundColor: Colors.Primary,
        '& .MuiButton-label': {
            paddingLeft: 10,
            paddingRight: 10,
            color: Colors.White,
        },
        '&:hover': {
            cursor: 'pointer',
            opacity: 0.88,
            backgroundColor: Colors.Primary,
        },
    },

    disabledActionButton: {
        backgroundColor: Colors.Gray8,
        width: 96,
        '& .MuiButton-label': {
            color: Colors.Gray10 + '!important',
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    header: {
        '& .MuiTypography-h6': {
            fontWeight: 'bold',
            fontSize: 18,
        },
    },
    modalContainer: {
        '& .MuiDialog-paperWidthSm': {
            width: 640,
        },
        '& .MuiDialogContent-root': {
            backgroundColor: Colors.SmokeBackground,
        },
    },
    wait: {
        position: 'absolute',
        display: 'flex',
        top: '50%',
        left: '50%',
        zIndex: 100,
    },
}));

const numberDigitsError = i18n.t('errors:ringGroups.numberOnlyDigits');
const numberMaxLengthError = i18n.t('errors:ringGroups.numberMaxLength');
const nameMaxLengthError = i18n.t('errors:extensions.max32Length');
const requiredFieldError = i18n.t('errors:common.emptyInput');

export const parkingSlotValidationSchema = Yup.object().shape({
    id: Yup.string()
        .max(5, numberMaxLengthError)
        .matches(/^[0-9]*$/, numberDigitsError)
        .required(requiredFieldError),
    name: Yup.string()
        .max(32, nameMaxLengthError)
        .notRequired(),
});