import {TFunction} from "react-i18next";
import {Column} from "react-table";
import {AgentsTypeData} from "../../../hooks/agents/useAgentsHook";
import RingGroupCell from "../../Calls/RingGroupCell";
import PermissionLinkCell from "../../DataTable/PermissionLinkCell";
import history from "../../../history";
import {getRoute, Routes} from "../../../routes/routes";
import React from "react";
import PermissionProvider from "../../PermissionProvider/PermissionProvider";
import {Permission} from "../../../store/types/Permission";
import SwitchWithLabel from "../../SwitchWithLabel/SwitchWithLabel";
import {makeStyles} from "@material-ui/styles";
import CustomizedIconButton from "../../IconButton/IconButton";
import {Edit} from "@material-ui/icons";
import {Colors} from "../../../styles/Colors";
import CallerCell from "../../Calls/CallerCell";
import {UpdatedRingGroupMember} from "../../../store/actions/ringgroups/payloads";


export const useStyles = makeStyles(() => ({
    tableContainer:{
        '& tr td:nth-child(3)':{
            padding: '0px !important'
        }
    },
    switcher:{
        marginBottom:0,
        height:"auto"
    }
}))

export const generateColumns = (
    t: TFunction<string>,
    handleChangeState: (value:UpdatedRingGroupMember)=>void,
    classes?: ReturnType<typeof useStyles>
): Column<AgentsTypeData>[] => {


    return [
        {
            Header: t<string>('screens:extensions.extension'),
            accessor: 'extension',
            Cell: function Cell(params) {

                if (params.row.original.isGroup && params.row.original.groupName) {
                    return (
                        <div id={'group_row'}>
                            <RingGroupCell
                                title={params.row.original.groupName}
                                link={params.row.original.link || ''}
                            />
                        </div>
                    )
                }

                return (

                    <PermissionLinkCell
                        text={params.row.original.extension || ''}
                        onClick={() =>
                            history.push(
                                getRoute(Routes.ExtensionsDetails, {id: params.row.original.extension})
                            )
                        }
                        bold={true}
                    />

                );
            },
            width: 1,
            minWidth: 80,
        },
        {
            Header: t<string>('common:name'),
            accessor: 'name',
            Cell: function Cell(params) {
                return (
                    params.row.original.name || ''
                );
            },
            width: 1,
            minWidth: 140,
        },
        {
            Header:'',
            accessor: 'custom_id',
            Cell: function Cell(params) {
                return (
                    <>
                        <CallerCell
                            type={params.row.original.status?.callType}
                            groupName={params.row.original.isGroup ? params.row.original.groupName : undefined}
                        />
                    </>
                );
            },
            minWidth: 24,
            maxWidth: 24
        },
        {
            Header: t<string>('common:status'),
            accessor: 'status',
            Cell: function Cell(params) {
                const text = params.row.original.status?.text || '';
                const color = params.row.original.status?.color || 'inherit'

                return (
                    <>
                        <span style={{color: color}}>{text}</span>
                    </>
                );
            },
            minWidth: 140,
            width: 1,
        },
        {
            Header: t<string>('screens:calls.duration'),
            accessor: 'duration',
            Cell: function Cell(params) {

                const isOnHold = params.row.original.status?.isOnHold
                const color = isOnHold ? params.row.original.status?.color : 'inherit'

                return (
                   <span style={{color:color}}>{params.row.original.status?.duration || ''}</span>
                );
            },
            minWidth: 140,
            width: 1,
        },
        {
            Header: t<string>('screens:supervisorPanel.loggedIn'),
            accessor: 'isLogged',
            Cell: function Cell(params) {

                if (params.row.original.isGroup) {
                    return <></>
                }

                if (!params.row.original.updatedRingGroupMember) {
                    return <></>
                }

                return (
                    <div>
                        <PermissionProvider
                            permission={
                                Permission.SupervisorPanel.Agents.value}
                        >
                            <SwitchWithLabel
                                label={""}
                                field={'dispatchType'}
                                dataQa={`group-member-ringing-${
                                    params.row.original.id ?? params.row.index
                                }`}
                                // @ts-ignore
                                setValue={(name, value) => handleChangeState(params.row.original.updatedRingGroupMember) }
                                value={
                                    params.row.original?.isLogged || false
                                }
                                customDisabledTooltip={t('common:noPermissionToResource')}
                                className={classes?.switcher}
                            />
                        </PermissionProvider>
                    </div>
                );
            },
            minWidth: 140,
            width: 1,
        },
        {
            Header: t<string>('common:actions'),
            accessor: 'id',
            Cell: function Cell(params) {

                if (params.row.original.isGroup) {
                    return <></>
                }

                return (
                    <PermissionProvider
                        permission={
                            Permission.SupervisorPanel.Agents.value}
                    >
                        <CustomizedIconButton
                            onClick={() =>
                                history.push(
                                    getRoute(Routes.ExtensionsDetails, {id: params.row.original.extension})
                                )
                            }
                            dataTestId="edit-list-item-button"
                            dataQa="edit-list-item-button"
                            tooltipText={t('common:edit')}
                        >
                            <Edit htmlColor={Colors.ListIconColor}/>
                        </CustomizedIconButton>
                    </PermissionProvider>);
            },
            minWidth: 140,
            width: 1,
        }
    ];
};

