import React from 'react';
import { useStyles } from './PaymentMethod.utils';
import SvDefaultPaymentMethod from "./DefaultPaymentMethod/SvDefaultPaymentMethod";
import SvAutoPayments from "./AutoPayments/SvAutoPayments";

const SvPaymentMethod = () => {
    const classes = useStyles();

    return (
        <div>
            <SvDefaultPaymentMethod />
            <div className={classes.separator} />
            <SvAutoPayments />
        </div>
    );
};

export default SvPaymentMethod;
