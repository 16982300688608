import { ServiceFeature } from './ServiceFeature';

export enum IndividualType {
    Supervisor = 1,
    Manager = 2,
    SupervisorName='hunt_group_supervisor'
}

export interface CustomerInfo {
    customer_info: CustomerInfoDetails;
    main_office_info: CustomerInfoDetails;
    reseller_info: CustomerInfoDetails;
}

export interface CustomerInfoDetails {
    address_line_2?: string;
    api_token?: string;
    aux_fields?: unknown[];
    baddr1?: string;
    balance?: number;
    balance_transfer_allowed?: string;
    bcc?: string;
    bill_status?: string;
    bill_suspension_delayed?: number;
    billed_to?: Date;
    billing_lock?: string;
    blocked?: string;
    bp_charge_cc?: string;
    bp_closure_delayed_info?: unknown;
    bundle_billed_to?: Date;
    callshop_enabled?: string;
    city?: string;
    commission_plan_effective_from?: Date;
    companyname?: string;
    cont1?: string;
    cont2?: string;
    country?: string;
    creation_date?: Date;
    credit_exceed?: number;
    credit_limit?: number;
    credit_limit_until?: Date;
    credit_limit_warning?: unknown[];
    deactivate_on?: Date;
    discount_rate?: number;
    email?: string;
    email_from?: string;
    estimate_taxes?: number;
    euuid?: string;
    faxnum?: string;
    firstname?: string;
    generate_invoice_earlier?: string;
    has_custom_fields?: number;
    i_acl?: number;
    i_balance_control_type?: CustomerBillingType;
    i_billing_period?: number;
    i_billing_processor?: number;
    i_commission_plan?: number;
    i_customer?: number;
    i_customer_class?: number;
    i_customer_type?: number;
    i_distributor?: number;
    i_do_batch?: number;
    i_lang?: string;
    i_main_office?: number;
    i_number_scope?: number;
    i_number_scope_reseller?: number;
    i_office_type?: CustomerOfficeType;
    i_parent?: number;
    i_rep?: number;
    i_role?: number;
    i_tariff?: number;
    i_tariff_incoming?: number;
    i_template?: number;
    i_time_zone?: number;
    i_traffic_profile?: number;
    i_ui_time_zone?: number;
    i_vd_plan?: number;
    in_date_format?: string;
    in_time_format?: string;
    inclusive_taxation?: string;
    invoice_generation_enabled?: number;
    ip_filter?: string;
    is_used?: number;
    iso_4217?: string;
    lastname?: string;
    login?: string;
    login_allowed_ip_list?: string[];
    max_abbreviated_length?: number;
    midinit?: string;
    name?: string;
    new_i_billing_period?: number;
    new_i_time_zone?: number;
    next_billed_to?: Date;
    note?: string;
    notepad?: string;
    onetime_invoice_generation_enabled?: number;
    opening_balance?: number;
    ot_i_template?: number;
    ot_send_invoices?: string;
    out_date_format?: string;
    out_date_time_format?: string;
    out_time_format?: string;
    override_tariffs_enabled?: string;
    password?: string;
    password_lifetime?: number;
    password_timestamp?: Date;
    payment_commission_rate?: number;
    payment_method_info?: unknown;
    pdf_generation_mode?: string;
    perm_credit_limit?: number;
    phone1?: string;
    phone2?: string;
    purge_after_months?: number;
    refnum?: string;
    restore_on?: Date;
    rt_auth_info?: unknown;
    sale_commission_rate?: number;
    salutation?: string;
    scheduled_billing_change?: Date;
    scheduled_status_change_list?: unknown[];
    send_invoices?: string;
    send_statistics?: string;
    service_features?: ServiceFeature[];
    service_flags?: string;
    shifted_billing_date?: Date;
    state?: string;
    status?: string;
    subreseller_status?: number;
    suspend_on_insuff_funds?: string;
    suspension_delay_date?: Date;
    tax_id?: string;
    tax_info?: string;
    temp_credit_limit?: number;
    terminate_on?: Date;
    time_zone_name?: string;
    ui_time_zone_name?: string;
    unallocated_payments?: number;
    zip?: string;
}

export interface CustomerISO {
    customer_info: {
        iso_4217: string;
    };
}

export interface GetGlobalCurrencyInfoResponse {
    global_currency_info: GlobalCurrencyInfo;
}

export interface GlobalCurrencyInfo {
    name_major: string;
    iso_4217: string;
    iso_4217_num: string;
    name: string;
    decimal_digits: number;
    name_minor: string;
}

export interface CustomerCustomFieldSchema {
    i_custom_field: number;
    mandatory: 'Y' | 'N';
    name: string;
    personal_data: 'Y' | 'N';
    properties: string;
    type: string;
    value: string;
    visible_to_end_user: 'Y' | 'N';
}

export enum CustomerBillingStatus {
    Suspended = 'suspended',
    ProvisonallyTerminated = 'provisionally_terminated',
    Limited = 'limited',
    CreditExceed = 'credit_exceeded',
    NoAvailableFunds = 'no_available_funds',
    PaymentFrozen = 'frozen',
    SpendingLimitReached = 'spending_limit_reached',
}

export enum CustomerBillingType {
    Postpaid = 1,
    Prepaid = 2,
}

export enum CustomerOfficeType {
    Hq = 3,
    Branch = 2,
    NoOffices = 1
}