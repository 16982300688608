import React from "react";
import OverflowTooltip from "../OverflowTooltip/OverflowTooltip";
import {makeStyles} from '@material-ui/core';
import {Colors} from "../../styles/Colors";
import {usePermissionContext, usePermissions} from "../../hooks/usePermissions";
import {PermissionType} from "../../store/types/Permission";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
    bold:{
        '& span': {
            fontWeight:700
        }
    },
    url: {
        color: Colors.Secondary1,
        cursor: 'pointer',
        fontSize: 16,
        fontWeight: 400,
        display: 'initial',
        alignItems: 'center',

        '& > span': {
            marginLeft: 8
        }
    },

    blueText: {
        color: Colors.Link
    },

    disabled: {
        color: Colors.Text,
        fontSize: 16,
        fontWeight: 400,
    },

    twoRowsContainer: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },

    twoRowsText: {
        width: '100%',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
}));

interface PermissionLinkCellInterface {
    text: string;
    onClick: () => void;
    permissions?: string[];
    className?: string;
    customizePermission?: (permissionValue: PermissionType) => PermissionType;
    icon?: never;
    twoRowsText?: boolean;
    bold?:boolean
}

const PermissionLinkCell: React.VFC<PermissionLinkCellInterface> = (
    {
        text,
        onClick,
        permissions,
        className,
        customizePermission,
        icon,
        twoRowsText = false,
        bold = false
    }) => {
    const classes = useStyles();

    let permission;
    if (!!permissions) {
        permission = usePermissions(...permissions);
    } else {
        permission = usePermissionContext();
    }

    if (customizePermission) {
        permission = customizePermission(permission);
    }

    const linkActive = permission === PermissionType.Visible;

    return <OverflowTooltip
        tooltip={text}
        text={
            <span className={classNames(
                className,
                linkActive ? classes.url : classes.disabled
            )}
                  onClick={linkActive ? onClick : () => null}
            >
                {text}
                {icon && <span>{icon}</span>}
            </span>
        }
        copy={false}
        classes={{
            text: classNames(
                twoRowsText && classes.twoRowsContainer,
                linkActive && classes.blueText,
                bold && classes.bold
            )
        }}
    />
}

export default PermissionLinkCell;