import {createAsyncAction, createAction} from 'typesafe-actions';
import {AccountListResponse} from "../../types/Account";
import {GetHuntGroupListResponse, SipCall} from "../ringgroups/payloads";
import {AssignedExtension} from "../../types/RingGroup";
import {AgentListFilters} from "../../../components/Tabs/Agents/FiltersComponent";

export const getAgentsTabData = createAsyncAction(
    'GET_AGENTS_TAB_DATA',
    'GET_AGENTS_TAB_DATA_SUCCESSES',
    'GET_AGENTS_TAB_DATA_FAILED',
)<AgentListFilters, {
    accounts: AccountListResponse,
    huntGroupList: GetHuntGroupListResponse,
    sipCalls: SipCall[],
    agents: AssignedExtension[]
}, undefined>();

export const cacheAgents = createAction('CACHE_AGENTS')<{agents: AssignedExtension[]}>();
export const participantId = createAction('PARTICIPATION_ID')<{id?: string}>();
