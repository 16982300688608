import React from 'react';
import { makeStyles } from "@material-ui/core";
import { Colors } from "../../styles/Colors";
import { TFunction } from 'react-i18next';
import { ParkingSlotsListItem } from "../../store/actions/callParkingSlots/payloads";
import { Column } from "react-table";
import PermissionLinkCell from "../../components/DataTable/PermissionLinkCell";
import { usePermissions } from '../../hooks/usePermissions';
import { Permission, PermissionType } from '../../store/types/Permission';
import OverflowTooltip from '../../components/OverflowTooltip/OverflowTooltip';
import EmptyRowContent from '../../components/DataTable/EmptyRowContent';
import DataItemsActions from '../../components/DataTable/DataItemActions';

export const useStyles = makeStyles(() => ({
    mainContainer: {
        height: '100vh',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        background: Colors.SmokeBackground,

        '& .MuiButton-label': {
            color: Colors.White,
        },
    },
    scrollable: {
        overflowY: 'auto',
        flex: 1,
        padding: '50px 90px 120px 90px',
    },

    outsideContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },

    header: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: 40,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 25,
        '& h2': {
            fontSize: 32,
            lineHeight: '24px',
            marginBlockEnd: 8,
            marginBlockStart: 4,
            fontWeight: 500,
        },
        '& p': {
            fontSize: 14,
            lineHeight: '16px',
            opacity: 0.38,
            marginBlockStart: 0,
            marginBottom: 0,
        },
        '& .MuiButtonBase-root': {
            marginTop: '24px',
            marginBottom: '48px',
            width: '135px',
        },
    },
    actions: {
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
    },
    actionsWrapper: {
        justifyContent: 'center',
    },
    buttonsSeparation: {
        marginLeft: 30,
    },
    dataGrid: {
        '& thead th:nth-child(1)': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& thead th:nth-child(3)': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& tbody tr td:nth-child(1)': {
            display: 'flex',
            justifyContent: 'center',
        },
        '& tbody tr td:nth-child(1) > *': {
            flex: 'unset'
        },
    },
    containerDialog: {
        '& .MuiPaper-root': {
            width: 280,
            '& .MuiDialogContent-root': {
                minWidth: 'unset',
                background: Colors.White,
            },
        },
    },
    tableContainer: {
        maxWidth: 1040,
    }
}));

export const generateColumns = (
    t: TFunction<string>,
    classes: ReturnType<typeof useStyles>,
    onEdit: (item: ParkingSlotsListItem) => void,
    onDelete: (item: ParkingSlotsListItem) => void,
    validateBranchOffice: (value: PermissionType) => PermissionType
): Column<ParkingSlotsListItem>[] => {
    return [
        {
            accessor: 'id',
            Header: t<string>('screens:callParkingSlots.slotNumber'),
            width: 0.75,
            maxWidth: 180,
            Cell: function Cell(params) {
                const permission = usePermissions(...Permission.CloudPBX.CallParkingSlots.CallParkingSlotDetails.value);
                return (
                    <PermissionLinkCell
                        text={params.row.original.id}
                        onClick={() => {
                            (permission === PermissionType.Visible) && onEdit?.(params.row.original);
                        }}
                        permissions={Permission.CloudPBX.CallParkingSlots.CallParkingSlotDetails.value}
                    />
                );
            },
        },
        {
            accessor: 'name',
            width: 1.75,
            Header: t<string>('screens:callParkingSlots.description'),
            Cell: function Cell(params) {
                const description = params.row.original.name;
                if (description) {
                    return (
                        <OverflowTooltip
                            tooltip={description}
                            text={description}
                            copy={true}
                        />
                    );
                }
                return <EmptyRowContent />;
            },
        },
        {
            accessor: 'i_parking_slot',
            maxWidth: 267,
            minWidth: 267,
            Header: t<string>('common:actions'),
            Cell: function Cell(params) {
                return (
                    <div className={classes.actions}>
                        <div className={classes.actionsWrapper}>
                            <DataItemsActions
                                onEdit={() => onEdit(params.row.original)}
                                onDelete={() => onDelete(params.row.original)}
                                classes={{
                                    deleteButton: classes.buttonsSeparation,
                                }}
                                editPermission={
                                    Permission.CloudPBX.CallParkingSlots.CallParkingSlotDetails.value
                                }
                                deletePermission={
                                    Permission.CloudPBX.CallParkingSlots.DeleteCallParkingSlot.value
                                }
                                customizeEditPermission={validateBranchOffice}
                                customizeDeletePermission={validateBranchOffice}
                            />
                        </div>
                    </div>
                );
            },
        },
    ];
};