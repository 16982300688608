import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ReactComponent as NoPermission } from '../../assets/no-permission.svg';
import CustomizedButton from '../../components/Button/Button';
import { Colors } from '../../styles/Colors';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import UnifiedAccessDenied from "./UnifiedAccessDenied";

export interface AccessDeniedProps {
    subPageMode?: boolean;
    hideGoBackButton?: boolean;
}

export const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.SmokeBackground,
    },
    subPageModeRoot: {
        height: 'fit-content',
        padding: '0',
        alignItems: 'flex-start',
        marginTop: 25
    },
    title: {
        fontSize: 32,
        fontWeight: 500,
        color: Colors.Text,
    },
    description: {
        fontWeight: 400,
        color: Colors.Gray5,
        fontSize: 16
    },
    button: {
        marginTop: 30,
        marginBottom: 60,
        width: 210,
        '& .MuiButton-label': {
            color: Colors.White,
            padding: '0 25px',
        },
    },
    subpageInline: {
        display: 'inline-flex'
    },
    subPageTitle: {
        height: 31,
        color: Colors.Gray5,
        fontSize: 24,
        fontWeight: 500,
        marginLeft: 14
    },
    subPageIcon: {
        marginTop: 3
    },
    descriptionSubPage: {
        marginLeft: 30
    },
}));

const AccessDenied: React.VFC<AccessDeniedProps> = ({
    subPageMode,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    
    if(subPageMode) {
        return (
            <UnifiedAccessDenied title={t('common:accessDenied')+'. '}>{t('common:noPermissionToPage')}</UnifiedAccessDenied>
        );
    }

    return (
        <div className={classes.root}>
            <NoPermission />
            <h2 className={classes.title}>{t('common:accessDenied')}</h2>
            <span className={classes.description}>
                {t('common:noPermissionToPage')}
            </span>

            <CustomizedButton
                className={classes.button}
                primary
                onClick={history.goBack}
            >
                {t('common:goBack')}
            </CustomizedButton>
        </div>
    );
};

export default AccessDenied;
