import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {generateColumns, useStyles} from './CallParkingSlotList.utils';
import {useDispatch, useSelector} from 'react-redux';
import usePageTitle from '../../hooks/usePageTitle';
import {PaginationMode} from '../../components/DataGrid/types';
import Header from '../../components/ListViewHeader/Header';
import PermissionPlaceholder from '../../components/PermissionProvider/PermissionPlaceholder';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import {Permission, PermissionType} from '../../store/types/Permission';
import {useTranslation} from 'react-i18next';
import {actions} from '../../store';
import {ParkingSlotsListItem} from '../../store/actions/callParkingSlots/payloads';
import DataGrid from '../../components/DataGrid/DataGrid';
import {ReduxState} from '../../store/types';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import {DialogButton} from '../../components/AlertDialog/DialogContainer';
import EditCallParkingSlotDialog from './EditCallParkingSlotDialog';
import toast from 'react-hot-toast';
import {CustomerOfficeType, IndividualType} from '../../store/types/CustomerInfo';
import {useIndividualTypeHook} from "../../hooks/agents/useIndividualTypeHook";

export const CallParkingSlotList = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    usePageTitle();

    const {isSupervisor} = useIndividualTypeHook()
    const [forceFirstPage, setForceFirstPage] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(0);
    const {parkingSlotsList, isLoading} = useSelector(
        (state: ReduxState) => state.callParkingSlots,
    );
    const [rowToDelete, setRowToDelete] = useState<{
        isOpen: boolean,
        item?: ParkingSlotsListItem
    }>({
        isOpen: false
    });
    const [rowToEdit, setRowToEdit] = useState<{
        isOpen: boolean,
        item?: ParkingSlotsListItem
    }>({
        isOpen: false
    });

    const i_office_type: CustomerOfficeType = useSelector<ReduxState, CustomerOfficeType>(
        (state) => state.generic?.customerOfficeType || CustomerOfficeType.Hq
    );
    
    const validateBranchOffice = useCallback((value: PermissionType) => {
        if(value === PermissionType.Visible && i_office_type === CustomerOfficeType.Branch) {
            return PermissionType.ReadOnly;
        }
        return value;
    }, [i_office_type]);

    const fetchData = (params?: {
        pageSize: number;
        page: number;
    }) => {

        !isSupervisor && dispatch(actions.getCallParkingSlotsList.request({
            limit: params ? params.pageSize : pageSize,
            offset: params ? params.page * params.pageSize : page,
        }));
        !isSupervisor && dispatch(actions.getCallSettingsDetailsForPakingSlots.request());
    };
    
    useEffect(() => {
        fetchData();
    }, []);
    
    const columns = useMemo(
        () => generateColumns(t, classes, (item) => {
            setRowToEdit({
                isOpen: true,
                item: item
            });
        }, (item) => {
            setRowToDelete({
                isOpen: true,
                item: item
            });
        },
        validateBranchOffice), [validateBranchOffice],
    );

    const deleteParkingSlot = useCallback((item?: ParkingSlotsListItem) => {
        if(!item) return;
        dispatch(actions.deleteCallParkingSlot.request({
            i_parking_slot: item.i_parking_slot,
            callback: () => {
                setRowToDelete({
                    isOpen: false,
                    item: undefined,
                });
                toast(t<string>('tooltips:callParkingSlots.slotDeleted'));
                fetchData();
            }
        }));
    }, []);
    
    return (
        <PermissionPlaceholder permission={Permission.CloudPBX.CallParkingSlots.value} allowedForUserGroups={[IndividualType.Manager]}>
            <div className={classes.mainContainer}>
                <div className={classes.scrollable}>
                    <div className={classes.header}>
                        <PermissionProvider
                            permission={
                                Permission.CloudPBX.CallParkingSlots.AddNewParkingSlot.value
                            }
                            customizePermission={validateBranchOffice}
                        >
                            <Header
                                title={t('screens:callParkingSlots.callParkingSlot')}
                                totalNumber={parkingSlotsList.total || 0}
                                showDetails={false}
                                dataQa={'add-new-button'}
                                buttonText={t('screens:callParkingSlots.addNew')}
                                ofText={t('screens:callParkingSlots.disabled')}
                                buttonOnClick={() => {
                                    setRowToEdit({
                                        isOpen: true,
                                        item: undefined
                                    });
                                }}
                                buttonDataQa={'add-new-button-button'}
                                plusIcon={true}
                            />
                        </PermissionProvider>
                    </div>

                    <DataGrid<ParkingSlotsListItem>
                        columns={columns}
                        data={parkingSlotsList?.data}
                        rowCount={parkingSlotsList.total}
                        loading={isLoading}
                        centeredRows
                        customRowHeight={55}
                        className={classes.dataGrid}
                        classes={{
                            tableContainer: classes.tableContainer
                        }}
                        onPageChange={(params) => {
                            setForceFirstPage(false);
                            fetchData({
                                pageSize: params.pageSize,
                                page: params.page,
                            });
                            setPage(params.page);
                        }}
                        onPageSizeChange={(params) => {
                            setForceFirstPage(false);
                            setPageSize(params.pageSize);
                            setPage(0);
                            fetchData({
                                pageSize: params.pageSize,
                                page: 0,
                            });
                        }}
                        paginationMode={PaginationMode.Server}
                        forceFirstPage={forceFirstPage}
                        initialPageSize={10}
                    />
                </div>
                
                <AlertDialog
                    isOpen={rowToDelete.isOpen}
                    dataQa={'delete-phone-book-modal'}
                    hideHeader={true}
                    className={classes.containerDialog}
                    description={
                        t('screens:callParkingSlots.deleteCallParkingSlot',
                            {
                                value: rowToDelete.item?.id,
                            },
                        )
                    }
                    dialogActionsButtons={[
                        <DialogButton
                            key="cancel"
                            label={t('common:cancel')}
                            onClick={() => {
                                setRowToDelete({
                                    isOpen: false,
                                    item: undefined,
                                });
                            }}
                        />,
                        <DialogButton
                            key="delete"
                            label={t('common:delete')}
                            onClick={() => deleteParkingSlot(rowToDelete.item)}
                        />,
                    ]}
                />
                
                <PermissionProvider
                    permission={ rowToEdit?.item
                        ? Permission.CloudPBX.CallParkingSlots.CallParkingSlotDetails.value
                        : Permission.CloudPBX.CallParkingSlots.AddNewParkingSlot.value
                    }
                    customizePermission={validateBranchOffice}
                >
                    <EditCallParkingSlotDialog
                        isOpen={rowToEdit.isOpen}
                        toggleVisibility={(isSaved) => {
                            setRowToEdit({
                                isOpen: false,
                                item: rowToEdit?.item
                            });
                            setTimeout(() => {
                                setRowToEdit({
                                    isOpen: false,
                                    item: undefined
                                });
                            }, 200);
                            if(isSaved) {
                                fetchData();
                            }
                        }}
                        editedRow={rowToEdit.item}
                    />
                </PermissionProvider>
            </div>
        </PermissionPlaceholder>
    );
};