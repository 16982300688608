import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {actions} from "../../../store";
import {useAgentsHook} from "../../../hooks/agents/useAgentsHook";

import {Permission} from "../../../store/types/Permission";
import DataGrid from "../../DataGrid/DataGrid";
import {PaginationMode} from "../../DataGrid/types";
import PermissionProvider from "../../PermissionProvider/PermissionProvider";
import {generateColumns, useStyles} from "./AgentsTab.utils";
import {UpdatedRingGroupMember} from "../../../store/actions/ringgroups/payloads";
import {YesNo} from "../../../store/types/CallScreening";
import FiltersComponent, {AgentListFilters} from "./FiltersComponent";
import {SearchCallback} from "../../../views/CallHistory/CallHistory.utils";


export interface AgentsFiltersInterface {
    params?: { pageSize: number; page: number },
    filters?: AgentListFilters
}

const AgentsTab: React.VFC<SearchCallback>  = ({callback}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch();
    const classes = useStyles();
    const {data, isLoading, agentsCache, agentsItemsForFilter} = useAgentsHook();
    const [filters,setFilters] = useState<AgentListFilters>({})

    const fetchData = (payload?: AgentListFilters) => {

        let tempFilters = filters ? {...filters}: undefined
        if(tempFilters && tempFilters?.agentsFilter && tempFilters.agentsFilter.find(item => item.id === undefined))
        {
            tempFilters = {...tempFilters, agentsFilter:[]}
        }

        dispatch(actions.getAgentsTabData.request({...tempFilters || (payload ?? {}), isInit: payload?.isInit?? false}))
        callback && callback();
    }

    const columns = useMemo(
        () =>
            generateColumns(
                t,
                (obj: UpdatedRingGroupMember) => {
                    dispatch(actions.updateRingGroupMember.request({
                        ...obj,
                        extension: {
                            ...obj.extension,
                            hunt_active: obj.extension?.hunt_active == YesNo.Yes ? YesNo.No : YesNo.Yes
                        }
                    }))
                    fetchData({...filters})
                },
                classes
            ),
        [data],
    );

    useEffect(() => {
        fetchData({isInit: true})
    }, []);

    return (
        <div style={{maxWidth:1061}}>
            <PermissionProvider permission={Permission.SupervisorPanel.Agents.value}>

                <FiltersComponent
                    handleSearch={fetchData}
                    agentsList={agentsItemsForFilter}
                    sourceAgentList={agentsCache}
                    setFilter={(data)=> (setFilters({...filters, ...data}))}
                />

                <DataGrid
                    columns={columns}
                    data={data}
                    loading={isLoading}
                    paginationMode={PaginationMode.Server}
                    rowCount={data.length}
                    centeredRows
                    hidePagination
                    classes={{tableContainer:classes.tableContainer}}
                />

            </PermissionProvider>
        </div>

    )
}

export default AgentsTab