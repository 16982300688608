import {SipCallType} from "../../store/actions/ringgroups/payloads";
import CustomizedTooltip from "../Tooltip/Tooltip";
import React from "react";
import {useTranslation} from "react-i18next";
import {ReactComponent as IncomingCallIcon} from '../../assets/incomingCallList.svg';
import {ReactComponent as OutgoingCallIcon} from '../../assets/outgoingCallList.svg';

interface CallerCellInterface {
    groupName?: string,
    type?: SipCallType
}

const CallerCell: React.VFC<CallerCellInterface> = ({groupName, type}) => {

    if (groupName) {
        return <></>
    }

    if (!type) {
        return <></>
    }

    const {t} = useTranslation()

    const icon = type === SipCallType.Incoming
        ? (<IncomingCallIcon/>)
        : (<OutgoingCallIcon/>);
    const callTypeTooltip = t(
        `enums:sipCallType.${type}`,
    );

    return (
        <CustomizedTooltip title={callTypeTooltip} copy={false}>
            {icon}
        </CustomizedTooltip>
    )
}


export default CallerCell