import React, { useEffect, useMemo } from 'react';
import { EditCallParkingSlotDialogProps, parkingSlotValidationSchema, useStyles } from './EditCallParkingSlotDialog.utils';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PermissionProvider from '../../components/PermissionProvider/PermissionProvider';
import DialogContainer, { DialogButton } from '../../components/AlertDialog/DialogContainer';
import { Permission, PermissionType } from '../../store/types/Permission';
import Loader from '../../components/Loader/Loader';
import { Grid } from '@material-ui/core';
import TextField from '../../components/TextField/TextField';
import { useFormik } from 'formik';
import { ParkingSlotsListItem } from '../../store/actions/callParkingSlots/payloads';
import IconWithTooltip from '../../components/Tooltip/IconWithTooltip';
import { usePermissionContext } from '../../hooks/usePermissions';
import { actions } from '../../store';
import toast from 'react-hot-toast';
import { ReduxState } from '../../store/types';
import { ServiceFeature, ServiceFeatureName } from '../../store/types/ServiceFeature';

const EditCallParkingSlotDialog: React.VFC<EditCallParkingSlotDialogProps> = ({
    isOpen,
    editedRow,
    toggleVisibility
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const savingParkingSlot = false;

    const permission = usePermissionContext();
    const readOnly = permission !== PermissionType.Visible;

    const initialValues = useMemo(() => {
        return editedRow ?? {
            i_parking_slot: 0,
            id: '',
            name: '',
        } as ParkingSlotsListItem;
    }, [editedRow]);

    const {
        values,
        handleSubmit,
        handleChange,
        errors,
        setFieldError,
        isValid,
        resetForm,
        dirty
    } = useFormik<ParkingSlotsListItem>({
        initialValues,
        onSubmit: (form) => {
            if(editedRow) {
                const obj = {
                    ...form,
                    i_parking_slot: editedRow.i_parking_slot
                } as ParkingSlotsListItem;
                dispatch(
                    actions.editCallParkingSlot.request({
                        ...obj,
                        callback: () => {
                            toggleVisibility?.(true);
                            resetForm();
                            toast(t<string>('tooltips:callParkingSlots.slotEdited'));
                        },
                    }),
                );
            } else {
                dispatch(
                    actions.addCallParkingSlot.request({
                        id: form.id,
                        name: form.name,
                        callback: () => {
                            toggleVisibility?.(true);
                            resetForm();
                            toast(t<string>('tooltips:callParkingSlots.slotAdded'));
                        },
                    }),
                );
            }
        },
        enableReinitialize: true,
        validationSchema: parkingSlotValidationSchema,
        validateOnChange: false,
        validateOnBlur: false,
    });

    const disableSaveButton = useMemo(() => {
        return !(values?.id?.length ?? 0) || permission !== PermissionType.Visible || !dirty;
    }, [values, permission]);

    const { dialingRules, customerServiceFeatures} = useSelector(
        (state: ReduxState) => state.callSettings,
    );
    
    const {addEditApiError} = useSelector(
        (state: ReduxState) => state.callParkingSlots,
    );
    
    const retrieval_prefix = useMemo(() => {
        const voiceDialingFeature = customerServiceFeatures?.find(
            (v) => v.name === ServiceFeatureName.VoiceDialing,
        ) as ServiceFeature;
        const selectedRuleId1 = voiceDialingFeature?.attributes.find(
                (v) => v.name === 'i_dial_rule',
            )?.values[0] || 0;
        const index = dialingRules?.findIndex(
            (v) => v?.i_dialing_rule == selectedRuleId1,
        ) ?? -1;
        const selectedRuleIndex = index === -1 ? 0 : index;
        return dialingRules
            ?.[selectedRuleIndex].dial_codes
            ?.call_release || '*71';
    }, [dialingRules, customerServiceFeatures]);

    const duplicatedName = t('errors:callParkingSlots.duplicatedName');
    useEffect(() => {
        if(addEditApiError && !(errors?.id)) {
            setFieldError('id', duplicatedName);
        }
    }, [addEditApiError, duplicatedName, errors, setFieldError]);

    return (<PermissionProvider permission={Permission.CloudPBX.CallParkingSlots
            [editedRow ? 'CallParkingSlotDetails' : 'AddNewParkingSlot'].value}>
        <DialogContainer
            isOpen={isOpen}
            dataQa="add-edit-parking-slot-dialog"
            header={
                editedRow
                    ? t('screens:callParkingSlots.editParkingSlotTitle')
                    : t('screens:callParkingSlots.addNewParkingSlotTitle')
            }
            headerClass={classes.header}
            className={classes.modalContainer}
            dialogActionsButtons={[
                <DialogButton
                    className={classes.primaryActionButton}
                    key="cancel"
                    label={t('common:cancel')}
                    onClick={() => {
                        resetForm();
                        toggleVisibility?.(false);
                    }}
                />,
                <DialogButton
                    primary
                    key="save"
                    label={t('common:save')}
                    onClick={handleSubmit}
                    disabled={disableSaveButton || !isValid}
                />,
            ]}
        >
            {savingParkingSlot && (
                <Loader dataQa={'slot-inner-loader'} absolutePosition />
            )}
            <form
                onSubmit={handleSubmit}
                autoComplete="off"
                data-testid="slots-form"
            >
                <Grid item className={classes.itemsContainer}>
                    <TextField
                        id="id"
                        label={t('screens:callParkingSlots.slotNumber')}
                        onChange={(e) => {
                            handleChange(e);
                            if(errors?.id) {
                                dispatch(actions.cleanApiError());
                                setTimeout(() => {
                                    setFieldError('id', undefined);
                                }, 10);
                            }
                        }}
                        value={values.id}
                        icon={
                            <IconWithTooltip
                                dataQa="parking-slot-id-tooltip"
                                tooltipText={t(
                                    'tooltips:callParkingSlots.slotNumberTooltip',
                                    {
                                        retrieval_prefix: retrieval_prefix
                                    }
                                )}
                            />
                        }
                        iconPosition="end"
                        dataQa="parking-slot-id-input"
                        readOnly={readOnly}
                        maxLength={5}
                        required
                        helperText={errors?.id}
                        setFieldError={setFieldError}
                    />
                    <TextField
                        id="name"
                        label={t('screens:callParkingSlots.description')}
                        onChange={handleChange}
                        value={values.name}
                        dataQa="parking-slot-name-input"
                        readOnly={readOnly}
                        maxLength={32}
                        helperText={errors?.name}
                        setFieldError={setFieldError}
                        icon={
                            <IconWithTooltip
                                dataQa="phone-book-description-tooltip"
                                tooltipText={t('tooltips:callParkingSlots.descriptionTooltip')}
                                copy={false}
                            />
                        }
                        iconPosition="end"
                    />
                </Grid>
            </form>
        </DialogContainer>
    </PermissionProvider>);
};

export default EditCallParkingSlotDialog;